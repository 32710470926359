<template lang="html">
  <div
    class="calendar"
    style="margin-top:0px;margin-left:auto;margin-right:auto;display:block-inline;"
  >
    <div class="calendar-nav">
      <div class="calendar-nav-previous-month">
        <button class="button is-small is-text">
          <svg viewBox="0 0 50 80" xml:space="preserve">
            <polyline
              fill="none"
              stroke-width=".5em"
              stroke-linecap="round"
              stroke-linejoin="round"
              points="45.63,75.8 0.375,38.087 45.63,0.375 "
            />
          </svg>
        </button>
      </div>
      <div class="calendar-month">March 2017</div>
      <div class="calendar-nav-next-month">
        <button class="button is-small is-text">
          <svg viewBox="0 0 50 80" xml:space="preserve">
            <polyline
              fill="none"
              stroke-width=".5em"
              stroke-linecap="round"
              stroke-linejoin="round"
              points="0.375,0.375 45.63,38.087 0.375,75.8 "
            />
          </svg>
        </button>
      </div>
    </div>
    <div class="calendar-container">
      <div class="calendar-header">
        <div class="calendar-date">Sun</div>
        <div class="calendar-date">Mon</div>
        <div class="calendar-date">Tue</div>
        <div class="calendar-date">Wed</div>
        <div class="calendar-date">Thu</div>
        <div class="calendar-date">Fri</div>
        <div class="calendar-date">Sat</div>
      </div>
      <div class="calendar-body">
        <div class="calendar-date is-disabled">
          <button class="date-item">26</button>
        </div>
        <div class="calendar-date is-disabled">
          <button class="date-item">27</button>
        </div>
        <div class="calendar-date is-disabled">
          <button class="date-item">28</button>
        </div>
        <div class="calendar-date"><button class="date-item">1</button></div>
        <div class="calendar-date"><button class="date-item">2</button></div>
        <div class="calendar-date"><button class="date-item">3</button></div>
        <div class="calendar-date tooltip" data-tooltip="Today">
          <button class="date-item is-today">4</button>
        </div>
        <div
          class="calendar-date tooltip"
          data-tooltip="Not available"
          disabled=""
        >
          <button class="date-item">5</button>
        </div>
        <div class="calendar-date"><button class="date-item">6</button></div>
        <div class="calendar-date"><button class="date-item">7</button></div>
        <div class="calendar-date tooltip" data-tooltip="You have appointments">
          <button class="date-item">8</button>
        </div>
        <div class="calendar-date"><button class="date-item">9</button></div>
        <div class="calendar-date"><button class="date-item">10</button></div>
        <div class="calendar-date"><button class="date-item">11</button></div>
        <div class="calendar-date"><button class="date-item">12</button></div>
        <div class="calendar-date"><button class="date-item">13</button></div>
        <div class="calendar-date"><button class="date-item">14</button></div>
        <div class="calendar-date"><button class="date-item">15</button></div>
        <div class="calendar-date"><button class="date-item">16</button></div>
        <div class="calendar-date"><button class="date-item">17</button></div>
        <div class="calendar-date"><button class="date-item">18</button></div>
        <div class="calendar-date"><button class="date-item">19</button></div>
        <div class="calendar-date"><button class="date-item">20</button></div>
        <div class="calendar-date"><button class="date-item">21</button></div>
        <div class="calendar-date"><button class="date-item">22</button></div>
        <div class="calendar-date"><button class="date-item">23</button></div>
        <div class="calendar-date"><button class="date-item">24</button></div>
        <div class="calendar-date"><button class="date-item">25</button></div>
        <div class="calendar-date"><button class="date-item">26</button></div>
        <div class="calendar-date"><button class="date-item">27</button></div>
        <div class="calendar-date"><button class="date-item">28</button></div>
        <div class="calendar-date"><button class="date-item">29</button></div>
        <div class="calendar-date"><button class="date-item">30</button></div>
        <div class="calendar-date"><button class="date-item">31</button></div>
        <div class="calendar-date is-disabled">
          <button class="date-item">1</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="css"></style>

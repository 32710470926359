<template lang="html">
  <nav
    id="mainNav"
    v-show="loggedOn"
    aria-label="main navigation"
    style="padding-bottom:5;"
    class="navbar is-primary"
    role="navigation"
  >
    <div class="navbar-brand" style="margin-bottom:5px">
      <router-link to="/subjects">
        <a class="logo" style="margin:5px 0 auto 5px;">
          <img
            style="margin-top:6px;"
            src="../../assets/logo.svg"
            alt="BrainCake"
            width="50"
            height="28"
          />
        </a>
      </router-link>

      <div
        v-if="navClass == 'navbar-menu'"
        class="navbar-burger"
        @click="navClass = 'navbar-menu is-active'"
      >
        <span id="menBurg1" class="icon">
          <i class="fas fa-user" aria-hidden="true" style="font-size:20px" />
        </span>
      </div>

      <div
        v-if="navClass == 'navbar-menu is-active'"
        role="button"
        class="navbar-burger"
        aria-label="menu"
        aria-expanded="false"
        @click="navClass = 'navbar-menu'"
      >
        <span id="menBurg2" class="icon">
          <i class="fas fa-times" aria-hidden="true" style="font-size:20px"
        /></span>
      </div>
    </div>

    <div class="navbar-end " style="color:000000;">
      <div :class="navClass">
        <!-- hidden on mobile -->
        <div
          @mouseover="loadUseDataR()"
          class="navbar-item is-hoverable"
          style="min-width: 100px;color:000000;"
        >
          <a class="navbar-link">
            <strong> {{ $store.state.userData.email }}</strong>
          </a>

          <div class="navbar-dropdown ">
            <router-link to="/subjects"
              ><a class="navbar-item" @click="navClass = 'navbar-menu'">
                Subjects
              </a></router-link
            >

            <router-link to="/pref"
              ><a
                class="navbar-item"
                @click="navClass = 'navbar-menu'"
                v-show="true"
              >
                Preferences
              </a></router-link
            >
            <router-link to="/ContentManager" v-show="admin"
              ><a
                class="navbar-item"
                @click="navClass = 'navbar-menu'"
                v-show="true"
              >
                Content Manager
              </a></router-link
            >

            <div>
              <div class="navbar-item">
                <a class="button is-discord" style="margin-right:5px;">
                  <span class="icon">
                    <i class="fab fa-discord"></i>
                  </span>
                </a>

                <a
                  class="button  is-facebook"
                  style="margin-right:5px;"
                  href="https://www.facebook.com/BrainCake.School"
                  target="_blank"
                >
                  <span class="icon">
                    <i class="fab fa-facebook-square"></i>
                  </span>
                </a>

                <a
                  class="button  is-twitter"
                  style="margin-right:5px;"
                  target="_blank"
                >
                  <span class="icon">
                    <i class="fab fa-twitter-square"></i>
                  </span>
                </a>
                <a
                  class="button "
                  target="_blank"
                  style="margin-right:5px;"
                  href="https://teespring.com/stores/braincakeschool"
                >
                  <span class="icon">
                    <i class="fas fa-tshirt"></i>
                  </span>
                </a>
              </div>
            </div>

            <hr class="navbar-divider" />

            <a class="" @click="logOut()">
              <a class="navbar-item">
                <span class="icon is-small"
                  ><i
                    class="fas fa-sign-in-alt"
                    aria-hidden="true"
                    style="margin-right:10px"
                /></span>
                <span><strong>Sign Out</strong></span>
              </a>
            </a>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import firebase from "firebase";
import router from "@/router";
export default {
  name: "UserNav",
  data() {
    return {
      navBool: false,
      navClass: "navbar-menu",
      showEl: false,
      loggedOn: firebase.auth().currentUser,
      admin: false
    };
  },
  methods: {
    logOut: function() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          router.push("About");
        });
      this.loggedOn = 0;
      //this.$store.state.userData.N.length = 0;
    },
    isLoggedOn: function() {
      if (firebase.auth().currentUser) {
        this.loggedOn = 1;
      } else {
        this.loggedOn = 0;
      }

      // console.log(this.loggedOn);
    },
    loadUseDataR() {
      let user = firebase.auth().currentUser;
      let val = 0;
      if (user) {
        firebase
          .database()
          .ref("users/" + user.uid)
          .on("value", function(snapshot) {
            val = snapshot.val();
            //vue.set(state, "userSubjectData", snapshot.val());
            //state.commit("setData", snapshot.val());
          });
        this.admin = val.admin;
      }
    }
  },
  computed: {},
  watch: {
    sharedState: function() {
      return 0;
    },
    loggedOn: function() {
      return 0;
    }
  },
  updated: function() {
    this.isLoggedOn;
    this.$nextTick(this.isLoggedOn);
    this.$nextTick(this.loadUseDataR);
  },
  mounted: function() {
    this.isLoggedOn;
    this.$nextTick(this.isLoggedOn);
    this.$nextTick(this.loadUseDataR);
    this.$forceUpdate();
  },
  created() {
    this.isLoggedOn;
    this.$nextTick(this.loadUseDataR);
    this.$forceUpdate();
  },
  destroyed() {
    this.isLoggedOn;
    this.$nextTick(this.isLoggedOn);
    this.$forceUpdate();
  }
};
</script>

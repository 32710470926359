<template>
  <div
    id="Vmain"
    class="box"
    style="height:auto%;;margin:auto;padding:0;box-shadow:0px 0px;"
  >
    <UserNav />
    <router-view />
  </div>
</template>

<style lang="scss">
#navMenu {
  background: #efa9c7;
}

#Vmain {
  font-family: "Josefin Sans", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000000;
  min-height: 100vh;
}
#nav {
  padding: 30px;
}

.tag:hover {
  background: #f4dcee;
}
</style>

<script>
import UserNav from "@/views/elements/elUserNav.vue";
export default {
  name: "Vmain",
  components: {
    UserNav
  },
  methods: {}
};
</script>

<template lang="html">
  <div id="UnitsCard" class="" style="height:100%;width:100%;">
    <div
      id="navMenu"
      class=""
      style="text-align:left;padding:20px;position:sticky;width:100%;top:-1px;z-index:10;"
    >
      <div class="" style="padding:0px">
        <span class="title" style="top:100px;">Agenda</span>
        <a class="" @click="eSub = !eSub">
          <span
            v-show="!eSub"
            class=" is-menuButton"
            style="font-size:30px;float:right;padding:auto;;margin:auto;"
          >
            <i class="fas fa-calendar" aria-hidden="true" style="" />
          </span>
          <span
            v-show="eSub"
            class=" is-menuButton"
            style="font-size:30px;float:right;padding:auto;;margin:auto;"
          >
            <i class="fas fa-times" aria-hidden="true" style="" />
          </span>
        </a>
      </div>

      <div
        v-show="eSub"
        class="card"
        style="padding:10px;margin:auto;width:350px;"
      >
        <h1><calendar /></h1>
      </div>
    </div>

    <div class="" style="width:100%;margin:auto;padding:30px;">
      <nav
        class="pagination is-centered"
        role="navigation"
        aria-label="pagination"
      >
        <a class="pagination-previous"
          ><span class="icon" style="float:left;">
            <i
              class="fas fa-chevron-left"
              aria-hidden="true"
              style=""
            ></i> </span
          ><span>Previous Day</span></a
        >
        <a class="pagination-next"
          ><span>Next Day</span
          ><span class="icon" style="float:left;">
            <i
              class="fas fa-chevron-right"
              aria-hidden="true"
              style=""
            /> </span
        ></a>
      </nav>

      <div class="timeline is-centered" style="">
        <header class="timeline-header is-link" style="margin:10px">
          <span class="tag is-large"
            ><span class="title"><u>?? / ?? / ??</u></span>
          </span>
        </header>
        <div v-show="eMod" v-for="i in 3" :key="i" class="timeline-item ">
          <div class="timeline-marker" />
          <div class="timeline-content">
            <a class="box has-background-light">
              <p class="heading" style="text-align:center">
                {{ i }} Unit Title
              </p>

              <p class="" style="text-align:center">
                <i
                  v-for="x in 5"
                  :key="x"
                  class="fas fa-star"
                  aria-hidden="true"
                  style="text-align:left"
                />
              </p>
              <p class="" style="text-align:center">#subject #board #level</p>
              <p style="text-align:center">Info about unit {{ i }}</p>

              <p style="text-align:center">
                <i
                  class="fas fa-sign-in-alt"
                  aria-hidden="true"
                  style="font-size:30px;"
                />
              </p>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import calendar from "@/views/elements/elCalendar.vue";
export default {
  data() {
    return {
      eSub: false,
      eMod: true
    };
  },
  components: {
    calendar
  }
};
</script>

<style lang="scss">
$col1: #db5d78;
$col2: #efa9c7;
$col3: #f4dcee;
$col4: #fdbdc2;
$col5: #f5f2f2;

#navMenu {
  background: $col2;
}
</style>

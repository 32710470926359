import Vue from "vue";
import Router from "vue-router";
import svAbout from "./views/subviews/svAbout.vue";
import svPref from "./views/subviews/svPref.vue";
import svLogin from "./views/subviews/svLogin.vue";
import svAgenda from "./views/subviews/svAgenda.vue";
import svSubSelect from "./views/subviews/svSubSelect.vue";
import svUnitSelect from "./views/subviews/svUnitSelect.vue";
import svTest from "./views/subviews/svTest.vue";
import svToDo from "./views/subviews/svToDo.vue";
import svContentManager from "./views/subviews/svContentManager.vue";
import firebase from "firebase";

Vue.use(Router);

let routeA = new Router({
  mode: "history",
  name: "router",
  routes: [
    {
      path: "*",
      redirect: "/about"
    },
    {
      path: "/",
      redirect: "/about"
    },
    {
      path: "/",
      redirect: "/about"
    },
    {
      path: "/about",
      name: "svAbout",
      component: svAbout,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: "/pref",
      name: "svPref",
      component: svPref,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/ContentManager",
      name: "svContentManager",
      component: svContentManager,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/login",
      name: "svLogin",
      component: svLogin,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: "/agenda",
      name: "svAgenda",
      component: svAgenda,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/subjects",
      name: "svSubSelect",
      component: svSubSelect,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/:subjectTitle/:subjectLevel/:subjectBoard/learn",
      name: "svUnitSelect",
      component: svUnitSelect,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/:subjectTitle/:subjectLevel/:subjectBoard/Test",
      name: "svTest",
      component: svTest,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/:subjectTitle/:subjectLevel/:subjectBoard/ToDo",
      name: "svToDo",
      component: svToDo,
      meta: {
        requiresAuth: true
      }
    }
  ]
});

routeA.beforeEach((to, from, next) => {
  let user = firebase.auth().currentUser;
  let requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  //let sS = to.params.subjectTitle;
  //let sL = to.params.subjectLevel;
  //let sB = to.params.subjectBoard;

  if (requiresAuth && !user) {
    //alert("go to about");
    next({ name: "svAbout" });
  } else if (!requiresAuth && user) {
    //alert("go to agenda");
    next({ name: "svSubSelect" });
  } else next(next);
});

export default routeA;

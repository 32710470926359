<template>
  <div class="about">
    <nav
      v-show="1"
      class="navbar is-primary is-fixed-top"
      role="navigation"
      aria-label="main navigation"
      style="position:sticky;top:0;z-index:1"
    >
      <div class="navbar-brand" style="z-index:-10;">
        <a href="#start" class="logo" style="margin:5px 5px 5px 5px;">
          <img
            src="../../assets/logo.svg"
            alt="BrainCake"
            width="39"
            height="17"
          />
        </a>
        <div
          v-if="navClass != 'is-active'"
          @click="navClass = 'is-active'"
          role="button"
          class="navbar-burger"
          aria-label="menu"
          aria-expanded="true"
        >
          <span aria-hidden="true" />
          <span aria-hidden="true" />
          <span aria-hidden="true" />
        </div>

        <div
          v-if="navClass == 'is-active'"
          @click="
            navClass = '';
            getsubjectList();
          "
          role="button"
          class="navbar-burger"
          aria-label="menu"
          aria-expanded="true"
        >
          <span aria-hidden="true" />
          <span aria-hidden="true" />
          <span aria-hidden="true" />
        </div>
      </div>

      <div class="navbar-menu" :class="navClass">
        <a class="navbar-item" @click="navClass = ''" href="#info">ABOUT</a>
        <a
          class="navbar-item"
          @click="
            navClass = '';
            getsubjectList();
          "
          href="#subjects"
          >SUBJECTS</a
        >
        <a class="navbar-item" @click="navClass = ''" href="#price">PRICE</a>
        <!--
        <a class="navbar-item" @click="navClass = ''" href="#sign_up"
          >SIGN UP</a
        > -->
        <a class="navbar-item" @click="navClass = ''" href="#contact"
          >CONTACT</a
        >
      </div>
    </nav>
    <nav
      class="navbar is-fixed-bottom is-transparent is-active"
      role="navigation"
      aria-label="main navigation"
      style="background-color:#FFFF0000"
    >
      <div
        class="navbar-menu is-active"
        style="background-color:#FFFF0000; margin:auto"
      >
        <div class="navbar-end">
          <div class="nav-item">
            <router-link to="/login">
              <button class="button is-large" style="margin:15px;">
                SIGN IN/UP
              </button>
            </router-link>
          </div>
        </div>
      </div>
    </nav>

    <section id="start" class="hero is-primary is-fullheight">
      <div class="hero-body">
        <div class="container">
          <img src="../../assets/logo.svg" alt="BrainCake" width="150" />

          <h1 class="title">BRAIN CAKE</h1>
          <h2 class="subtitle">
            Learn the right way,
            <br />a slice every day.
          </h2>
        </div>
      </div>
    </section>

    <section id="info" class="hero is-light is-fullheight">
      <div class="hero-body">
        <div class="container">
          <span class="icon has-text-primary">
            <i class="fas fa-5x fa-question"></i>
          </span>
          <br /><br /><br />
          <h1 class="title">About</h1>
          <h2 class="subtitle">
            <br />
            An E-learning platform for STEM education.
          </h2>
          <br />

          <div class="tile is-ancestor">
            <div class="tile" style="padding: 10px;">
              <!-- Add content or other tiles -->
              <article
                class="tile is-child notification"
                style="background-color:#fdbdc2;"
              >
                <div class="content">
                  <br />
                  <span class="icon has-text-dark">
                    <i class="fas fa-5x fa-book-open"></i>
                  </span>
                  <br /><br /><br />
                  <p class="title">R.O.R.I.</p>
                  <p class="subtitle">Read once remember indefinitely.</p>
                  <div class="content">
                    Read a piece of text once and directly transfer the critical
                    information into long term memory, minimising the need for
                    constant revision.
                  </div>
                </div>
              </article>
            </div>

            <div class="tile" style="padding: 10px;">
              <!-- Add content or other tiles -->
              <article
                class="tile is-child notification"
                style="background-color:#fdbdc2;"
              >
                <div class="content">
                  <br />
                  <span class="icon has-text-dark">
                    <i class="fas fa-5x fa-robot"></i>
                  </span>
                  <br /><br /><br />
                  <p class="title">BrainBot</p>
                  <p class="subtitle">
                    An interactive timetable to help you manage your time, keep
                    track of your current performance and schedule tasks to do.
                  </p>
                  <div class="content">
                    <!-- Content -->
                  </div>
                </div>
              </article>
            </div>

            <div class="tile" style="padding: 10px;">
              <!-- Add content or other tiles -->
              <article
                class="tile is-child notification"
                style="background-color:#fdbdc2;"
              >
                <div class="content">
                  <br />
                  <span class="icon has-text-dark">
                    <i class="fas fa-5x fa-pencil-ruler"></i>
                  </span>
                  <br /><br /><br />
                  <p class="title">Testing</p>
                  <p class="subtitle">
                    Exam-style and past paper questions to test your knowledge
                    and skills.
                  </p>
                  <div class="content">
                    <!-- Content -->
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="subjects" class="hero is-dark is-fullheight">
      <div class="hero-body">
        <div class="container">
          <span class="icon has-text-primary">
            <i class="fas fa-5x fa-book"></i>
          </span>
          <br /><br /><br />
          <h1 class="title">Subjects</h1>
          <h2 class="subtitle">
            We are adding more material every day.
          </h2>

          <table class="table is-fullwidth is-striped is-hoverable">
            <thead>
              <tr class="">
                <th>Subjects</th>
                <th>Level</th>
                <th>Board</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(i, index) of subjectList" :key="index" :value="index">
                <th>
                  {{ i.subject }}
                </th>
                <th>
                  {{ i.level }}
                </th>
                <th>
                  {{ i.board }}
                </th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>

    <section id="price" class="hero is-light is-fullheight">
      <div class="hero-body">
        <div class="container">
          <span class="icon has-text-primary">
            <i class="fas fa-5x fa-dollar-sign"></i>
          </span>
          <br /><br /><br />
          <h1 class="title">Price</h1>
          <br />
          <h2 class="subtitle">
            BrainCake is currently in <i><b>Alpha</b></i> stage and is currently
            <i><b>free</b></i
            >.
          </h2>

          <!--<h2 class="subtitle">After <i><b>Beta</b></i> it will be free for the first month then <b>£5</b> a month.</h2> -->
        </div>
      </div>
    </section>
    <!--
    <section id="sign_up" class="hero is-dark is-fullheight">
      <div class="hero-body">
        <div class="container">
          <span class="icon has-text-primary">
            <i class="fas fa-5x fa-user-plus"></i>
          </span>
          <h1 class="title">Sign Up</h1>
          <br />
          <h2 class="subtitle">
            BrainCake is currently in <i><b>Closed Alpha</b></i
            >.<br /><br />
            <u
              ><a href="#contact" class="has-text-primary is-size-3"
                >Contact Us</a
              ></u
            >
            if you would like to take part.
          </h2>
        </div>
      </div>
    </section>
-->
    <section id="contact" class="hero is-light is-fullheight">
      <div class="hero-body">
        <div class="container">
          <span class="icon has-text-primary">
            <i class="fas fa-5x fa-comment-alt"></i>
          </span>
          <br /><br /><br />
          <h1 class="title">Contact</h1>
          <br />
          <h2 class="subtitle">Want more information?</h2>
          <h2 class="subtitle">
            Email Us:<br /><br /><a
              href="mailto:info@braincake.school"
              class="has-text-primary is-size-4"
              ><u>info@braincake.school</u></a
            >
          </h2>
          <br />
          <a
            class="button is-medium is-facebook"
            href="https://www.facebook.com/BrainCake.School"
          >
            <span class="icon">
              <i class="fab fa-facebook"></i>
            </span>
            <span>facebook</span>
          </a>
          <br /><br />

          <a
            class="button is-medium is-linkedin"
            href="https://www.linkedin.com/company/braincake-school/"
          >
            <span class="icon">
              <i class="fab fa-linkedin"></i>
            </span>
            <span>LinkedIn</span>
          </a>
          <br /><br />
          <a
            class="button is-medium is-linkedin"
            href="https://teespring.com/stores/braincakeschool"
          >
            <span class="icon">
              <i class="fas fa-tshirt"></i>
            </span>
            <span>Merch</span>
          </a>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import firebase from "firebase";
export default {
  name: "About",
  data() {
    return {
      navClass: "",
      subjectList: {}
    };
  },
  methods: {
    getsubjectList: function() {
      var masterSubjectListRef = firebase.database().ref("subjects/");
      var SubList;
      masterSubjectListRef.on("value", function(snapshot) {
        SubList = snapshot.val();
        //console.log(SubList);
      });
      this.subjectList = SubList;
    }
  },
  mounted: function() {
    $(document).ready(function() {
      // Click event for any anchor tag that's href starts with #
      $('a[href^="#"]').click(function(event) {
        // The id of the section we want to go to.
        var id = $(this).attr("href");

        // An offset to push the content down from the top.
        var offset = 60;

        // Our scroll target : the top position of the
        // section that has the id referenced by our href.
        var target = $(id).offset().top - offset;

        // The magic...smooth scrollin' goodness.
        $("html, body").animate({ scrollTop: target }, 500);

        //prevent the page from jumping down to our section.
        event.preventDefault();
      });
    });
  },
  updated: function() {
    // this.$forceUpdate();
    this.getsubjectList;
    //this.$router.onReady(this.getsubjectList);
    //this.$nextTick(this.getsubjectList);
    //this.getsubjectList();
  },
  created: function() {
    this.$forceUpdate();
    //this.getsubjectList();
    this.getsubjectList;
    this.$router.onReady(this.getsubjectList);
    this.$nextTick(this.getsubjectList);
  }
};
</script>

<style lang="scss">
$col1: #db5d78;
$col2: #efa9c7;
$col3: #f4dcee;
$col4: #fdbdc2;
$col5: #f5f2f2;
#menBurg1,
#menBurg2 {
  background: #db5d78;
}
</style>

<template lang="html">
  <div class="" style="width:100%; min-width:380px;margin:auto; ">
    <div
      id="navMenu"
      class=""
      style="text-align:left;padding:20px;position:sticky;width:100%;top:-1px;z-index:10;"
    >
      <div class="" style="padding:0px">
        <span class="title" style="top:100px;">Content Manager</span>
      </div>
    </div>

    <div class="card  has-background-primary" style="margin:10px;padding:15px">
      <div class="field" @mouseover="getsubjectList()">
        <label class="label">Subject</label>
        <div class="control" @mouseover="getsubjectList()">
          <select v-model="inputSubject" @mouseover="getsubjectList()">
            <option
              v-for="(i, index) of subjectList"
              :key="index"
              :value="index"
              >{{ i.subject }} {{ i.level }} {{ i.board }}</option
            >
          </select>
        </div>
        <p class="help">Select subject to edit</p>

        <div class="field">
          <label class="label">Topic</label>
          <div class="control">
            <input
              class="input"
              type="text"
              v-model="inputTopic"
              placeholder="01"
              minlength="2"
              maxlength="2"
            />
          </div>
          <p class="help">This is a help text</p>
        </div>

        <div class="field">
          <label class="label">Subtopic</label>
          <div class="control">
            <input
              v-model="inputSubtopic"
              class="input"
              minlength="2"
              maxlength="2"
              type="text"
              placeholder="01"
            />
          </div>
          <p class="help">This is a help text</p>
        </div>

        <div class="tile is-child" style="text-align: center;">
          <button
            @click="getData()"
            style="margin:5px"
            class="button is-warming"
          >
            Load Data
          </button>
        </div>
      </div>
    </div>
    <div class="card  has-background-light" style="margin:10px;padding:15px">
      <div class="field">
        <label class="label">Subject Online</label>
        <div class="control">
          <input v-model="online" type="checkbox" name="vehicle1" />
        </div>
        <p class="help">{{ online }}</p>
      </div>

      <div class="field">
        <label class="label">Topic Heading</label>
        <div class="control">
          <input
            v-model="topicData.heading"
            class="input"
            type="text"
            placeholder="Heading"
          />
        </div>
        <p class="help" v-html="topicData.heading"></p>
      </div>

      <div class="field">
        <label class="label">Subtopic Heading</label>
        <div class="control">
          <input
            v-model="subData.heading"
            class="input"
            type="text"
            placeholder="Heading"
          />
        </div>
        <p class="help" v-html="subData.heading">This is a help text</p>
      </div>

      <div class="field">
        <label class="label">Content</label>
        <div class="control">
          <textarea
            style="height:200px;"
            v-model="subData.content"
            class="input"
            type="text"
            placeholder="Heading"
          ></textarea>
        </div>
        <p class="help" v-html="subData.content">This is a help text</p>
      </div>

      <!--
      <div class="field">
        <label class="label">Content Image</label>
        <div class="control">
          <input
            v-model="subData.contentImage"
            class="input"
            type="text"
            placeholder="Heading"
          />
        </div>
        <p class="help">
          <img :src="'/assets/' + inputSubject + '/' + subData.contentImage" />
        </p>
      </div>
      -->
    </div>

    <div class="card has-background-info" style="margin:10px;padding:15px;">
      <div class="field">
        <label class="label">Link Question</label>
        <div class="control">
          <textarea
            style="height:200px;"
            v-model="subData.MCQ.MCQuestion"
            class="input"
            type="text"
            placeholder="Heading"
          ></textarea>
        </div>
        <p class="help" v-html="subData.MCQ.MCQuestion">
          This is a help text
        </p>
      </div>

      <div class="field">
        <label class="label">Link Answer A</label>
        <div class="control">
          <input
            v-model="subData.MCQ.A"
            class="input"
            type="text"
            placeholder="Heading"
          />
        </div>
        <p class="help" v-html="subData.MCQ.A">
          This is a help text
        </p>
      </div>

      <div class="field">
        <label class="label">Link Answer B</label>
        <div class="control">
          <input
            v-model="subData.MCQ.B"
            class="input"
            type="text"
            placeholder="Heading"
          />
        </div>
        <p class="help" v-html="subData.MCQ.B">
          This is a help text
        </p>
      </div>

      <div class="field">
        <label class="label">Link Answer C</label>
        <div class="control">
          <input
            v-model="subData.MCQ.C"
            class="input"
            type="text"
            placeholder="Heading"
          />
        </div>
        <p class="help" v-html="subData.MCQ.C">
          This is a help text
        </p>
      </div>

      <div class="field">
        <label class="label">Link Answer D</label>
        <div class="control">
          <input
            v-model="subData.MCQ.D"
            class="input"
            type="text"
            placeholder="Heading"
          />
        </div>
        <p class="help" v-html="subData.MCQ.D">
          This is a help text
        </p>
      </div>

      <div class="field">
        <label class="label">Link correct Answer </label>
        <div class="control">
          <input
            v-model="subData.MCQ.Ans"
            class="input"
            type="text"
            placeholder="A/B/C/D"
            maxle
          />
        </div>
        <p class="help" v-html="subData.MCQ.Ans">
          This is a help text
        </p>
      </div>
    </div>

    <div
      class="card has-background-primary has-text-white"
      style="margin:10px;padding:15px;"
    >
      <div class="field">
        <label class="label">R question </label>
        <div class="control">
          <textarea
            style="height:200px;"
            v-model="subData.R"
            class="input"
            type="text"
            placeholder=""
          >
          </textarea>
        </div>
        <p class="help" v-html="subData.R"></p>
      </div>
      <div class="field">
        <label class="label">R Answer </label>
        <div class="control">
          <input
            v-model="subData.Rans"
            class="input"
            type="text"
            placeholder=""
          />
        </div>
        <p class="help" v-html="subData.Rans"></p>
      </div>
    </div>

    <div
      class="card has-background-primary has-text-white"
      style="margin:10px;padding:15px;"
    >
      <div class="field">
        <label class="label">E question </label>
        <div class="control">
          <textarea
            style="height:200px;"
            v-model="subData.E"
            class="input"
            type="text"
            placeholder=""
          >
          </textarea>
        </div>
        <p class="help" v-html="subData.E"></p>
      </div>
      <div class="field">
        <label class="label">E Answer </label>
        <div class="control">
          <input
            v-model="subData.Eans"
            class="input"
            type="text"
            placeholder=""
          />
        </div>
        <p class="help" v-html="subData.Eans"></p>
      </div>
    </div>

    <div
      class="card has-background-primary has-text-white"
      style="margin:10px;padding:15px;"
    >
      <div class="field">
        <label class="label">C question </label>
        <div class="control">
          <textarea
            style="height:200px;"
            v-model="subData.C"
            class="input"
            type="text"
            placeholder=""
          >
          </textarea>
        </div>
        <p class="help" v-html="subData.C"></p>
      </div>
      <div class="field">
        <label class="label">C Answer </label>
        <div class="control">
          <input
            v-model="subData.Cans"
            class="input"
            type="text"
            placeholder=""
          />
        </div>
        <p class="help" v-html="subData.Cans"></p>
      </div>
    </div>

    <div
      class="card has-background-primary has-text-white"
      style="margin:10px;padding:15px;"
    >
      <div class="field">
        <label class="label">I question </label>
        <div class="control">
          <textarea
            style="height:200px;"
            v-model="subData.I"
            class="input"
            type="text"
            placeholder=""
          >
          </textarea>
        </div>
        <p class="help" v-html="subData.I"></p>
      </div>
      <div class="field">
        <label class="label">I Answer </label>
        <div class="control">
          <input
            v-model="subData.Ians"
            class="input"
            type="text"
            placeholder=""
          />
        </div>
        <p class="help" v-html="subData.Ians"></p>
      </div>
    </div>

    <div
      class="card has-background-primary has-text-white"
      style="margin:10px;padding:15px;"
    >
      <div class="field">
        <label class="label">P question </label>
        <div class="control">
          <textarea
            style="height:200px;"
            v-model="subData.P"
            class="input"
            type="text"
            placeholder=""
          >
          </textarea>
        </div>
        <p class="help" v-html="subData.P"></p>
      </div>
      <div class="field">
        <label class="label">P Answer </label>
        <div class="control">
          <input
            v-model="subData.Pans"
            class="input"
            type="text"
            placeholder=""
          />
        </div>
        <p class="help" v-html="subData.Pans"></p>
      </div>
    </div>

    <div
      class="card has-background-primary has-text-white"
      style="margin:10px;padding:15px;"
    >
      <div class="field">
        <label class="label">V question </label>
        <div class="control">
          <textarea
            style="height:200px;"
            v-model="subData.V"
            class="input"
            type="text"
            placeholder=""
          >
          </textarea>
        </div>
        <p class="help" v-html="subData.V"></p>
      </div>
      <div class="field">
        <label class="label">V Answer </label>
        <div class="control">
          <input
            v-model="subData.Vans"
            class="input"
            type="text"
            placeholder=""
          />
        </div>
        <p class="help" v-html="subData.Vans"></p>
      </div>
    </div>

    <div
      class="card has-background-primary has-text-white"
      style="margin:10px;padding:15px;"
    >
      <div class="field">
        <label class="label">Summary Text </label>
        <div class="control">
          <textarea
            style="height:200px;"
            v-model="subData.Notes"
            class="input"
            type="text"
            placeholder=""
          >
          </textarea>
        </div>
        <p class="help" v-html="subData.Notes"></p>
      </div>
    </div>

    <div class="card has-text-white" style="margin:10px;padding:15px;">
      <div class="tile is-child" style="text-align: center;">
        <button
          @click="saveData()"
          style="margin:5px;float:left"
          class="button is-success"
        >
          Save Subtopic
        </button>

        <button
          @click="deleteSubtopic()"
          style="margin:5px;float:right"
          class="button is-danger"
        >
          Delete Subtopic
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import router from "@/router";
import firebase, { functions } from "firebase";
export default {
  data() {
    return {
      show1: false,
      subData: { MCQ: {} },
      topicData: "",
      online: true,
      userdat: {
        subscribed: false
      },
      inputSubject: "",
      inputTopic: "01",
      inputSubtopic: "01"
    };
  },
  methods: {
    getsubjectList: function() {
      var masterSubjectListRef = firebase.database().ref("subjects/");
      var SubList;
      masterSubjectListRef.on("value", function(snapshot) {
        SubList = snapshot.val();
        //console.log(SubList);
      });
      this.subjectList = SubList;
    },
    getSubtopicData: function() {
      var SubjectDataRef = firebase
        .database()
        .ref(
          "subjects/" +
            this.inputSubject +
            "/" +
            "topics/" +
            "topic" +
            this.inputTopic +
            "/" +
            "subtopic" +
            this.inputSubtopic
        );
      var data;
      SubjectDataRef.on("value", function(snapshot) {
        data = snapshot.val();
      });
      this.subData = data;
      //console.log(this.subData);
    },
    getTopicData: function() {
      var SubjectDataRef = firebase
        .database()
        .ref(
          "subjects/" +
            this.inputSubject +
            "/" +
            "topics/" +
            "topic" +
            this.inputTopic
        );
      var data;
      SubjectDataRef.on("value", function(snapshot) {
        data = snapshot.val();
      });
      this.topicData = data;
      //console.log(this.subData);
    },
    getOnline: function() {
      var SubjectDataRef = firebase
        .database()
        .ref("subjects/" + this.inputSubject);
      var data;
      SubjectDataRef.on("value", function(snapshot) {
        data = snapshot.val();
      });
      this.online = data.online;
      //console.log(data.online);
    },

    getData: function() {
      this.getTopicData();
      this.getSubtopicData();
      this.getOnline();
      alert("Data Loaded.");
    },
    saveOnlineData: function() {
      var SubjectDataRef = firebase
        .database()
        .ref("subjects/" + this.inputSubject);

      SubjectDataRef.update({ online: this.online });
    },
    saveSubtopicData: function() {
      var SubjectDataRef = firebase
        .database()
        .ref(
          "subjects/" +
            this.inputSubject +
            "/" +
            "topics/" +
            "topic" +
            this.inputTopic +
            "/" +
            "subtopic" +
            this.inputSubtopic
        );

      SubjectDataRef.update(this.subData);
    },
    saveTopicHeading: function() {
      var SubjectDataRef = firebase
        .database()
        .ref(
          "subjects/" +
            this.inputSubject +
            "/" +
            "topics/" +
            "topic" +
            this.inputTopic
        );

      SubjectDataRef.update({ heading: this.topicData.heading });
    },
    saveData: function() {
      console.log(this.subData);
      this.saveOnlineData();
      this.saveSubtopicData();
      this.saveTopicHeading();
      alert("Data Saved.");
    },
    deleteSubtopic: function() {
      firebase
        .database()
        .ref(
          "subjects/" +
            this.inputSubject +
            "/" +
            "topics/" +
            "topic" +
            this.inputTopic +
            "/" +
            "subtopic" +
            this.inputSubtopic
        )
        .remove();
      alert(
        "Deleted: subtopic" +
          this.inputSubtopic +
          ", topic" +
          this.inputTopic +
          ", " +
          this.inputSubject
      );
    },
    deleteTopic: function() {
      firebase
        .database()
        .ref(
          "subjects/" +
            this.inputSubject +
            "/" +
            "topics/" +
            "topic" +
            this.inputTopic
        )
        .remove();
      alert("Deleted: topic" + this.inputTopic + ", " + this.inputSubject);
    }
  },
  updated() {
    this.getsubjectList;
    this.$router.onReady(this.getsubjectList);
  },
  created() {
    this.getsubjectList;
    this.$router.onReady(this.getsubjectList);
    this.$nextTick(this.getsubjectList);
  },
  computed: {}
};
</script>

<style lang="css"></style>

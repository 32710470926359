<template lang="html">
  <transition name="fade">
    <div id="UnitsCard" class="" style="height:100%;width:100%;">
      <div
        id="navMenu"
        class=""
        style="text-align:left;padding:2px 10px;position:sticky;width:100%;top:-1px;z-index:1;display: inline-block;"
      >
        <!-- Main container -->
        <nav class="level">
          <!-- Left side -->
          <div class="level-left">
            <div class="level-item">
              <span style="">
                <span class="title" style="top:100px;"
                  >{{ $route.params.subjectTitle }}
                </span>
                <span class="subtitle" style="top:100px;font-size:15px;">
                  #{{ $route.params.subjectLevel }} #{{
                    $route.params.subjectBoard
                  }}
                </span>
              </span>
            </div>
          </div>
          <div class="level-left">
            <div class="level-item">
              <div class=" is-medium is-primary" style="margin:10px;">
                <h2 class="title">
                  <strong>Testing</strong>
                </h2>
              </div>
            </div>
          </div>
        </nav>
      </div>
      <!-- Right side -->

      <div class="tile is-ancestor " style="margin:10px;" v-show="!sh_menu">
        <!-- content card-->
        <div
          v-show="!sh_test && !sh_sumNotes"
          class="tile notification box"
          style="margin:10px;padding:25px;"
        >
          <section style="text-align:left;" class="">
            <h1
              v-html="this.subjectData.heading"
              class="title"
              style="margin:10px;text-align:center;font-size:50px;"
            ></h1>
            <div class="content" style="">
              <h2
                class="subtitle"
                style="font-size:24px;"
                :class="[hiClass]"
                v-html="this.subjectData.content"
              ></h2>
            </div>

            <div class=" box has-text-centered" style="margin:auto;">
              <figure class="image is-centered">
                <img
                  class=""
                  style="width:300px;display:inline"
                  :src="
                    '/assets/' +
                      $route.params.subjectTitle +
                      '_' +
                      $route.params.subjectLevel +
                      '_' +
                      $route.params.subjectBoard +
                      '/' +
                      this.subjectData.contentImage
                  "
                />
              </figure>
            </div>
          </section>
        </div>

        <div class="tile is-vertical ">
          <!-- Testing Card-->
          <div class="tile notification is-dark" style="margin:10px;padding:0;">
            <section style="margin:auto">
              <div class="" style="">
                <h1 class="title" style="margin-top:30px;">
                  {{ currentTopic }} test
                </h1>

                <h2 v-if="pass == 0" class="subtitle">
                  {{ CorrectAnswers }}
                </h2>

                <h2 v-if="pass == 1" class="subtitle">
                  PASSED!
                </h2>

                <h2 v-if="pass == 2" class="subtitle">
                  TRY AGAIN!
                </h2>

                <span
                  class="icon is-large"
                  style="x;margin-bottom:20px;width:75%;"
                >
                  <progress
                    style=""
                    class="progress is-primary is-large"
                    :value="progBarVal"
                    max="100"
                  ></progress>
                </span>

                <div
                  class="card notification is-rounded"
                  style="border-radius: 5px;padding:1px;background-color:;margin: 0px 15px;"
                >
                  <h2
                    class="subtitle"
                    style="margin:30px;"
                    v-html="subjectData.MCQ.MCQuestion"
                  ></h2>

                  <div
                    class="control is-centered"
                    style="text-align:center;margin-top:15px;"
                  >
                    <label
                      class="radio"
                      style="margin-right:50px;margin-left:50px;margin-bottom:10px;display:block;"
                    >
                      <input
                        type="radio"
                        name="Ans"
                        id="A"
                        value="A"
                        v-model="link_ans"
                      />
                      A. <span v-html="subjectData.MCQ.A"></span>
                    </label>

                    <label
                      class="radio"
                      style="margin-right:50px;margin-left:50px;margin-bottom:10px;display:block;"
                    >
                      <input
                        type="radio"
                        name="Ans"
                        id="B"
                        value="B"
                        v-model="link_ans"
                      />
                      B. <span v-html="subjectData.MCQ.B"></span>
                    </label>

                    <label
                      class="radio"
                      style="margin-right:50px;margin-left:50px;margin-bottom:10px;display:block;"
                    >
                      <input
                        type="radio"
                        name="Ans"
                        id="C"
                        value="C"
                        v-model="link_ans"
                      />
                      C. <span v-html="subjectData.MCQ.C"></span>
                    </label>

                    <label
                      class="radio"
                      style="margin-right:50px;margin-left:50px;margin-bottom:10px;display:block;"
                    >
                      <input
                        type="radio"
                        name="Ans"
                        id="D"
                        value="D"
                        v-model="link_ans"
                      />
                      D. <span v-html="subjectData.MCQ.D"></span>
                    </label>
                  </div>

                  <h2
                    v-show="link_ans_bool == 0"
                    class="subtitle"
                    style="margin:30px;"
                  >
                    What is the answer?
                  </h2>

                  <h2
                    v-show="link_ans_bool == 1"
                    class="subtitle"
                    style="margin:30px;"
                  >
                    Corect, the answer is
                    <span v-html="subjectData.MCQ.Ans"></span>.
                  </h2>

                  <h2
                    v-show="link_ans_bool == 2"
                    class="subtitle"
                    style="margin:30px;"
                  >
                    Wrong, the answer is
                    <span v-html="subjectData.MCQ.Ans"></span>.
                  </h2>

                  <h2
                    v-show="link_ans_bool == 3"
                    class="subtitle"
                    style="margin:30px;"
                  >
                    You got {{ CorrectAnswers }}. You pass!
                  </h2>

                  <h2
                    v-show="link_ans_bool == 4"
                    class="subtitle"
                    style="margin:30px;"
                  >
                    You got {{ CorrectAnswers }}. Try again later!
                  </h2>
                </div>
              </div>

              <div
                class="buttons has-addons is-centered is-rounded is-large is-fullwidth"
                style="padding:10px;"
              >
                <button
                  class="button is-large is-info is-light is-inverted is-outlined is-rounded"
                  style="background-color:;"
                  :disabled="link_ans_bool != 0"
                  @click="
                    checkTestAns();
                    checkTestResult();
                  "
                >
                  <strong>Check</strong>

                  <span class="icon">
                    <i class="fas fa-check"></i>
                  </span>
                </button>

                <button
                  v-if="currentQ < testQNum"
                  class="button is-large is-info is-light is-inverted is-outlined is-rounded"
                  style="background-color:;"
                  :disabled="link_ans_bool == 0"
                  @click="
                    currentQ++;
                    randomPage();
                    getSubData();
                    link_ans_bool = 0;
                    link_ans = '';
                  "
                >
                  <span>
                    <strong>Next</strong>
                  </span>

                  <span class="icon">
                    <i class="fas fa-forward"></i>
                  </span>
                </button>

                <button
                  v-if="currentQ == testQNum"
                  class="button is-large is-info is-light is-inverted is-outlined is-rounded"
                  style="background-color:;"
                  :disabled="link_ans_bool == 0"
                  @click="
                    updateUserTestData();
                    currentQ = 0;
                    CorrectAnswers = 0;
                    sh_menu = 1;
                    link_ans_bool = 0;
                    link_ans = '';
                  "
                >
                  <span>
                    <strong>End</strong>
                  </span>

                  <span class="icon">
                    <i class="fas fa-forward"></i>
                  </span>
                </button>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import firebase from "firebase";
export default {
  data() {
    return {
      renderComponent: true,
      currentTopic: "topic01",
      currentSubtopic: "subtopic01",
      subjectData: {},
      topicData: {
        heading: "GCSE"
      }
    };
  },
  components: {},
  computed: {},
  methods: {},
  watch: {},
  updated: function() {},
  created: function() {},
  computed: {}
};
</script>

<style lang="scss">
.red {
  background-color: #efa9c7;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>

import Vue from "vue";
import Vuex from "vuex";
import firebase from "firebase";

//import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userData: null,
    userSubjectData: null,
    masterSubjectData: {
      Maths: {
        online: false,
        board: {
          onine: false
        },
        level: {
          online: false
        },
        modules: {
          Maths_A2_EDEXCEL: {
            1: {
              name: "module1",
              info: "info about blah",
              units: {
                1: {
                  name: "unit 1"
                }
              }
            },
            2: {
              name: "module2"
            }
          }
        }
      }
    }
  },
  mutations: {
    loadUserData(state) {
      let user = firebase.auth().currentUser;

      if (user) {
        // move user data to store state
        state.userData = user;

        firebase
          .database()
          .ref("users/" + user.uid + "/subjects")
          .on("value", function(snapshot) {
            state.userSubjectData = snapshot.val();
          });

        firebase
          .database()
          .ref("users/" + user.uid)
          .on("value", function(snapshot) {
            state.userDataR = snapshot.val();
          });
        //  state.dispatch("loadUserData");
        //context.dispatch("loadUserSubjectData");, v
        //    state.dispatch("loadMasterSubjectData");
        // get master subject list from database
        /// get subscribed Subjects
      }
    },
    setData(store, data) {
      store.masterSubjectData = data;
      //this.$vue.set(store, location, data);
    },
    loadUserSubjectData(state) {
      let user = firebase.auth().currentUser;
      if (user) {
        firebase
          .database()
          .ref("users/" + user.uid + "/subjects")
          .on("value", function(snapshot) {
            state.userSubjectData = snapshot.val();
            //vue.set(state, "userSubjectData", snapshot.val());
            //state.commit("setData", snapshot.val());
          });
      }
    },
    loadMasterSubjectData(state) {
      firebase
        .database()
        .ref("subjects/")
        .on("value", function(snapshot) {
          state.masterSubjectData = snapshot.val();
        });
    }
  },
  getters: {
    doneTodos: state => {
      if (state.masterSubjectData) {
        //return state.masterSubjectData.filter(subjects => subjects.name);
      }
    }
  },
  /*computed: mapState([
    // map this.count to store.state.count
    "userData",
    "masterSubjectData",
    "userSubjectData"
  ]),*/
  actions: {}
});

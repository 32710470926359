<template lang="html">
  <transition name="fade">
    <div id="UnitsCard" class="" style="height:100%;width:100%;">
      <div
        id="navMenu"
        class=""
        style="text-align:left;padding:2px 10px;position:sticky;width:100%;top:-1px;z-index:1;display: inline-block;"
      >
        <!-- Main container -->
        <nav class="level">
          <!-- Left side -->
          <div class="level-left">
            <div class="level-item">
              <span style="">
                <span class="title" style="top:100px;"
                  >{{ $route.params.subjectTitle }}
                </span>
                <span class="subtitle" style="top:100px;font-size:15px;">
                  #{{ $route.params.subjectLevel }} #{{
                    $route.params.subjectBoard
                  }}
                </span>
              </span>
            </div>
          </div>
          <div class="level-left">
            <div class="level-item">
              <div class=" is-medium is-primary" style="margin:10px;">
                <h2 class="title">
                  <strong>ToDo</strong>
                </h2>
              </div>
            </div>
          </div>
        </nav>
      </div>
      <!-- Right side -->

      <div class="tile is-ancestor " style="margin:10px;" v-show="!sh_menu">
        <div class="tile is-vertical ">
          <!--sumary notes total card -->
          <div
            class="tile notification is-primary"
            style="margin:10px;padding:0;"
          >
            <section style="margin:auto">
              <div class="" style="">
                <span
                  class="icon is-large"
                  style="margin-top:35px;margin-bottom:25px;"
                >
                  <i class="fas fa-4x fa-list"></i>
                </span>

                <h1 class="title" style="margin-bottom:30px;">
                  ToDo
                </h1>
              </div>
              <div
                class="content card is-rounded"
                style="border-radius: 5px;padding:10px;background-color:;margin:10px;"
              >
                <ol type="1">
                  <div
                    v-for="(i, index1) of topicData[currentTopic]"
                    :key="index1"
                    class="card is-rounded"
                    style="border-radius: 5px;padding:10px;background-color:;margin:10px;"
                  >
                    <li style="margin-left:35px;font-size:25px">
                      <h2
                        class="subtitle"
                        style="margin:30px;font-size:25px"
                        v-html="i.Notes"
                      ></h2>
                    </li>
                  </div>
                </ol>
              </div>
              <div
                class="buttons has-addons is-centered is-rounded is-large is-fullwidth"
                style="padding:10px;"
              ></div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import firebase from "firebase";
export default {
  data() {
    return {
      renderComponent: true,
      currentTopic: "topic01",
      currentSubtopic: "subtopic01",
      subjectData: [],
      topicData: {
        heading: "GCSE"
      }
    };
  },
  components: {},
  computed: {},
  methods: {},
  watch: {},
  updated: function() {},
  created: function() {},
  computed: {}
};
</script>

<style lang="scss">
.red {
  background-color: #efa9c7;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>

<template lang="html">
  <div class="card has-background-light" style="" @mouseover="s = 1">
    <div
      id="navMenu"
      class=""
      style="text-align:left;padding:20px;position:sticky;width:100%;top:-1px;z-index:1;"
    >
      <div class="" style="padding:0px">
        <span class="title" style="top:100px;">Subjects</span>

        <router-link :to="'Pref'">
          <a
            style="float:right;"
            class="button"
            v-if="!$store.state.userData.emailVerified"
          >
            <span
              class=""
              style="font-size:125%;float:right;padding:auto;margin:auto;z-index"
            >
              <i class="fas fa-check" aria-hidden="true" style="" />
              Verify Email to start
            </span>
          </a>
        </router-link>

        <a
          v-show="!eSub"
          style="float:right;"
          v-if="$store.state.userData.emailVerified"
          class="button"
          @click="
            eSub = !eSub;
            getsubjectList();
          "
        >
          <span
            v-show="!eSub"
            class=" is-link"
            style="font-size:125%;float:right;padding:auto;margin:auto;z-index"
          >
            <i class="fas fa-plus" aria-hidden="true" style="" />
            Subjects
          </span>
        </a>
      </div>
      <div class="card" v-show="eSub" style="padding:10px;margin:10px;">
        <span v-show="loading">Loading...(close and reopen)</span>
        <div class="field is-horizontal" style="padding:0px;">
          <div class="field-body">
            <div class="field">
              <div class="select is-fullwidth" v-show="!admin">
                <select v-model="inputSubject">
                  <option
                    v-for="(i, index) of subjectList"
                    :key="index"
                    :value="index"
                    :disabled="!i.online"
                  >
                    {{ i.subject }} {{ i.level }} {{ i.board }}</option
                  >
                  >
                </select>
              </div>
              <div class="select is-fullwidth" v-show="admin">
                <select v-model="inputSubject">
                  <option
                    v-for="(i, index) of subjectList"
                    :key="index"
                    :value="index"
                  >
                    {{ i.subject }} {{ i.level }} {{ i.board }}
                    <span v-if="!i.online"> ----- offline</span>
                  </option>
                  >
                </select>
              </div>
            </div>

            <div class="control">
              <button
                @click="eSub = 0"
                class="button is-centered "
                style="margin-left:5px;float:right;"
              >
                <span class="icon" style="margin:auto;">
                  <i class="fas fa-times" aria-hidden="true" />
                </span>
                Close
              </button>
              <button
                @click="addSubject"
                class="button is-centered "
                style="margin-left:5px;float:right;"
              >
                <span class="icon" style="margin:auto;">
                  <i class="fas fa-plus" aria-hidden="true" />
                </span>
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      id="EditSub"
      class="card has-background-light"
      style="width:100%;margin:auto;padding:0px;"
    >
      <section class="hero is-medium" v-if="!$store.state.userSubjectData">
        <div class="hero-body">
          <div class="container">
            <h1 class="title">No subjects!</h1>
            <h2 class="subtitle">Start by adding a subject!!</h2>
          </div>
        </div>
      </section>

      <transition-group name="fade">
        <div v-for="i of $store.state.userSubjectData" :key="i.id">
          <div
            class="box is-active"
            style="border-radius:0px;margin:2px 0px 2px 0px"
          >
            <article class="media" style="">
              <div class="media-left" style="width:62%">
                <div class="content" style="text-align:left;">
                  <p>
                    <strong class="title" style="margin-bottom:500px;">
                      {{ i.heading }}
                    </strong>
                    <!--
              <progress class="progress is-small is-primary" v:model:value="i.progress" max="100" style="width:150px;">15%</progress>
--><br />
                    <span class="subtitle is-6"
                      >#{{ i.level }} #{{ i.board }}</span
                    >
                    <!--        <br>
            <span class="subtitle"> <i>{{i.description}}</i> </span>
            <br>
-->
                  </p>
                  <span
                    >last access: <i>{{ convertDate(i.lastAccess) }}</i> days
                    ago</span
                  >
                </div>
              </div>
              <div class="media-content"></div>
              <div class="media-right">
                <nav class="level">
                  <div class="level-item has-text-centered">
                    <router-link
                      :to="{
                        name: 'svUnitSelect',
                        params: {
                          subjectTitle: i.name,
                          subjectLevel: i.level,
                          subjectBoard: i.board
                        }
                      }"
                      ><a
                        @click="
                          updateLastAccess(
                            i.name + '_' + i.level + '_' + i.board
                          )
                        "
                      >
                        <figure class="" style="margin-right:30px;">
                          <p
                            class="image is-64x64"
                            style="margin:5px;border-radius:100%;background:#efa9c7; border-color:black;border-size:5px;"
                          >
                            <span class="icon" style="margin:20px;">
                              <i class="far fa-lightbulb fa-3x"></i>
                            </span>
                          </p>
                          <p class="subtitle" style="margin-left:6px;">
                            <b>Learn </b>
                          </p>
                        </figure>
                      </a>
                    </router-link>
                  </div>
                  <div class="level-item has-text-centered">
                    <router-link
                      @click.native="gotoTop"
                      :to="{
                        name: 'svTest',
                        params: {
                          subjectTitle: i.name,
                          subjectLevel: i.level,
                          subjectBoard: i.board
                        }
                      }"
                      ><a
                        @click="
                          updateLastAccess(
                            i.name + '_' + i.level + '_' + i.board
                          )
                        "
                      >
                        <figure class="" v-show="0">
                          <p
                            class="image is-64x64"
                            style="margin:5px;border-radius:100%;background:#efa9c7; border-color:black;border-size:5px;"
                          >
                            <span class="icon" style="margin:20px;">
                              <i class="fas fa-pencil-ruler fa-3x"></i>
                            </span>
                          </p>
                          <p class="subtitle" style="margin-left:6px;">
                            <b>Test </b>
                          </p>
                        </figure>
                      </a>
                    </router-link>
                  </div>
                  <div class="level-item has-text-centered">
                    <router-link
                      :to="{
                        name: 'svToDo',
                        params: {
                          subjectTitle: i.name,
                          subjectLevel: i.level,
                          subjectBoard: i.board
                        }
                      }"
                      ><a
                        @click="
                          updateLastAccess(
                            i.name + '_' + i.level + '_' + i.board
                          )
                        "
                      >
                        <figure class="" v-show="0">
                          <p
                            class="image is-64x64"
                            style="margin:5px;border-radius:100%;background:#efa9c7; border-color:black;border-size:5px;"
                          >
                            <span class="icon" style="margin:20px;">
                              <i class="fas fa-list fa-3x"></i>
                            </span>
                          </p>
                          <p class="subtitle" style="margin-left:6px;">
                            <b>ToDo </b>
                          </p>
                        </figure>
                      </a>
                    </router-link>
                  </div>
                  <div class="level-item has-text-centered">
                    <a @click="deleteSubject(i.name, i.level, i.board)">
                      <figure class="">
                        <p
                          class="image is-32x32"
                          style="margin:5px;margin-top:38px;margin-left:16px;border-radius:100%;background:#efa9c7; border-color:black;border-size:5px;font-size:10px;"
                        >
                          <span
                            class="icon is-small"
                            style="margin:auto;margin-top:9px"
                          >
                            <i class="fas fa-times fa-3x"></i>
                          </span>
                        </p>
                        <p
                          class="subtitle"
                          style="margin-left:6px;font-size:15px;text-align:center;"
                        >
                          <b>Remove </b>
                        </p>
                      </figure>
                    </a>
                  </div>
                </nav>
              </div>
            </article>
          </div>
        </div>
      </transition-group>
    </div>
  </div>
</template>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>

<script>
import firebase from "firebase";
export default {
  components: {},
  data() {
    return {
      eSub: false,
      inputSubject: "",
      subjectList: 0,
      admin: false,
      loading: true
    };
  },
  methods: {
    splitWord() {
      let a = 0;
      a = this.inputWord;
      var words = a.split("_");
      return 100; //words[0]; //a[0];
    },
    getsubjectList: function() {
      if (this.subjectList == 0) {
        var masterSubjectListRef = firebase.database().ref("subjects/");
        var SubList = 0;
        masterSubjectListRef.on("value", function(snapshot) {
          SubList = snapshot.val();
          //console.log(SubList);
        });
        this.subjectList = SubList;
      }
      if (SubList != 0) this.loading = false;
    },
    addSubject: function() {
      let uid = firebase.auth().currentUser.uid;
      let hasSubject = false;
      let subjectId = firebase
        .database()
        .ref()
        .child("users/" + this.uid + "/subjects")
        .push().key;

      let userSubjectDataRef = firebase
        .database()
        .ref(
          "/users/" +
            uid +
            "/subjects/" +
            this.selectedSubject +
            "_" +
            this.selectedLevel +
            "_" +
            this.selectedBoard
        );
      if (this.$store.state.userSubjectData) {
        if (
          this.$store.state.userSubjectData[
            this.selectedSubject +
              "_" +
              this.selectedLevel +
              "_" +
              this.selectedBoard
          ]
        ) {
          hasSubject = true;
        }
      }
      if (!hasSubject) {
        userSubjectDataRef.update({
          name: this.selectedSubject,
          heading: this.$store.state.masterSubjectData[
            this.selectedSubject +
              "_" +
              this.selectedLevel +
              "_" +
              this.selectedBoard
          ].subject,
          board: this.selectedBoard,
          level: this.selectedLevel,
          id: subjectId,
          progress: this.subtopicList,
          lastAccess: this.currentTime,
          startDate: this.currentTime
        });
        this.eSub = false;
      } else alert("you are alread subscribed to this subject");
    },
    deleteSubject: function(sub, lvl, brd) {
      let uid = firebase.auth().currentUser.uid;

      firebase
        .database()
        .ref("/users/" + uid + "/subjects/" + sub + "_" + lvl + "_" + brd)
        .remove();

      console.log(
        "/users/" +
          uid +
          "/subjects/" +
          sub +
          "_" +
          lvl +
          "_" +
          brd +
          " removed"
      );
    },
    updateLastAccess: function(SubjectString) {
      let uid = firebase.auth().currentUser.uid;
      let subjectId = firebase
        .database()
        .ref()
        .child("users/" + this.uid + "/subjects")
        .push().key;

      let userSubjectDataRef = firebase
        .database()
        .ref("/users/" + uid + "/subjects/" + SubjectString);

      userSubjectDataRef.update({
        lastAccess: this.currentTime
      });
    },
    convertDate: function(oldD) {
      const _MS_PER_DAY = 1000 * 60 * 60 * 24;
      var a = new Date();
      var b = new Date(oldD);
      // Discard the time and time-zone information.
      const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
      const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

      return Math.abs(Math.floor((utc2 - utc1) / _MS_PER_DAY));

      //diffDays.getDate() +"/" +diffDays.getMonth() +"/" +diffDays.getFullYear()
    },
    loadUseDataAdmin() {
      let user = firebase.auth().currentUser;
      let val = 0;
      firebase
        .database()
        .ref("users/" + user.uid)
        .on("value", function(snapshot) {
          val = snapshot.val();
          //vue.set(state, "userSubjectData", snapshot.val());
          //state.commit("setData", snapshot.val());
        });
      this.admin = val.admin;
    },
    gotoTop: function() {
      window.scrollTo(0, 0);
    }
  },
  computed: {
    currentTime: function() {
      let time = new Date();
      return time;
    },
    avLevel: function() {
      return 0; //this.$store.getters.doneTodos;
    },
    selectedSubject: function() {
      let a = 0;
      a = this.inputSubject;
      var words = a.split("_");
      return words[0]; //a[0];
    },
    selectedLevel: function() {
      let a = 0;
      a = this.inputSubject;
      var words = a.split("_");
      return words[1]; //a[0];
    },
    selectedBoard: function() {
      let a = 0;
      a = this.inputSubject;
      var words = a.split("_");
      return words[2]; //a[0];
    },
    subtopicList: function() {
      return 0; //a[0];
    }
  },
  updated: function() {
    //this.$nextTick(this.getsubjectList);
    this.getsubjectList;
    this.loadUseDataAdmin;
    this.$router.onReady(this.loadUseDataAdmin);
    this.$nextTick(this.loadUseDataAdmin);

    //console.log(this.currentSubtopic);
    //console.log(this.currentTopic);
  },
  created: function() {
    this.getsubjectList;
    this.$router.onReady(this.getsubjectList);
    this.$nextTick(this.getsubjectList);
    this.loadUseDataAdmin;
    this.$router.onReady(this.loadUseDataAdmin);
    this.$nextTick(this.loadUseDataAdmin);
  }
};
</script>

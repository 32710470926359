import Vue from "vue";
import Vmain from "./views/main.vue";
import router from "./router";
import store from "./store";

import * as firebase from "firebase/app";
import "firebase/analytics";
import "./assets/sass/main.scss";

//import "bulma/bulma.sass";
Vue.config.productionTip = false;

let app;
var config = {
  apiKey: "AIzaSyAC9BTG0kAb2dF71N852Whr_dYaetOODsk",
  authDomain: "braincake-209118.firebaseapp.com",
  databaseURL: "https://braincake-209118.firebaseio.com",
  projectId: "braincake-209118",
  storageBucket: "braincake-209118.appspot.com",
  messagingSenderId: "71435532610",
  appId: "1:71435532610:web:18f7d9941a63be65b9f0db",
  measurementId: "G-8PQW5QPXTG"
};
firebase.initializeApp(config);
//console.log(firebase);
firebase.analytics();
Vue.prototype.$analytics = firebase.analytics();

firebase.auth().onAuthStateChanged(function() {
  store.commit("loadUserData");
  store.commit("loadUserSubjectData");
  store.commit("loadMasterSubjectData");
  //console.log(store.state.userData)
  ///console.log("logged on");
  if (!app) {
    app = new Vue({
      store,
      router,
      render: h => h(Vmain)
    }).$mount("#Vmain");
  }
  //  });
});

<template lang="html">
  <div
    id="UnitsCard"
    class="is-danger"
    style="height:150%;width:100%;box-shadow:0px0px;backgroud-color:red;"
  >
    <div
      id="navMenu"
      class=""
      style="text-align:left;padding:2px 10px;position:sticky;width:100%;top:-1px;z-index:1;display: inline-block;backgroud-color:red;"
    >
      <!-- heading container -->
      <nav class="level">
        <!-- Left side -->
        <div class="level-left">
          <div class="level-item">
            <button
              class="button is-medium is-primary"
              style="margin:10px;"
              @click="
                sh_menu = !sh_menu;
                getProgress();
                resetData();
                setCurrentScrollPosition();
              "
            >
              <span class="icon">
                <i class="fas fa-bars"></i>
              </span>
              <strong>Topics</strong>
            </button>

            <span style="">
              <span class="title" style="top:100px;"
                >{{
                  this.$store.state.masterSubjectData[
                    $route.params.subjectTitle +
                      "_" +
                      $route.params.subjectLevel +
                      "_" +
                      $route.params.subjectBoard
                  ].subject
                }}
              </span>
              <span class="subtitle" style="top:100px;font-size:15px;">
                #{{ $route.params.subjectLevel }} #{{
                  $route.params.subjectBoard
                }}
              </span>
            </span>
          </div>
        </div>
      </nav>
    </div>

    <div class="tile is-ancestor " style="margin:10px;" v-show="!sh_menu">
      <!-- content card-->
      <div
        v-if="!sh_test && !sh_sumNotes"
        class="tile notification box"
        style="margin:10px;padding:25px;"
      >
        <section style="text-align:left;" class="">
          <button
            @click="
              content_alias = resetContent;
              subjectData.content = resetContent;
            "
            class="button  is-primary is-outlined is-rounded"
            style="background-color:;"
          >
            <span class="icon">
              <i class="fas fa-paint-roller"></i>
            </span>
            <span>
              <strong>Clear</strong>
            </span>
          </button>
          <h1
            v-html="subjectData.heading"
            class="title"
            style="margin:10px;text-align:center;font-size:50px;"
          ></h1>
          <div class="content" style="">
            <h2
              @mouseup="logSelection(subjectData.content)"
              class="subtitle"
              style="font-size:24px;"
              :class="[hiClass]"
              v-html="content_alias"
            ></h2>
          </div>

          <div class=" box has-text-centered" style="margin:auto;" v-show="0">
            <figure class="image is-centered">
              <img :src="subjectData.contentImage" />
            </figure>
          </div>
        </section>
      </div>

      <div class="tile is-vertical ">
        <!-- Testing Card-->
        <div
          class="tile notification is-dark"
          style="margin:10px;padding:0;"
          v-if="sh_test"
        >
          <section style="margin:auto">
            <div class="" style="">
              <h1 class="title" style="margin-top:30px;">
                {{ topicData[currentTopic].heading }} Test
              </h1>

              <h2 v-if="pass == 0" class="subtitle">
                {{ CorrectAnswers }}
              </h2>

              <h2 v-if="pass == 1" class="subtitle">
                PASSED!
              </h2>

              <h2 v-if="pass == 2" class="subtitle">
                TRY AGAIN!
              </h2>

              <span
                class="icon is-large"
                style="x;margin-bottom:20px;width:75%;"
              >
                <progress
                  style=""
                  class="progress is-primary is-large"
                  :value="progBarVal"
                  max="100"
                ></progress>
              </span>

              <div
                class="card notification is-rounded"
                style="border-radius: 5px;padding:1px;background-color:;margin: 0px 15px;"
              >
                <h2
                  class="subtitle"
                  style="margin:30px;"
                  v-html="subjectData.MCQ.MCQuestion"
                ></h2>

                <div
                  class="control is-centered"
                  style="text-align:center;margin-top:15px;"
                >
                  <label
                    class="radio"
                    style="margin-right:50px;margin-left:50px;margin-bottom:10px;display:block;"
                  >
                    <input
                      type="radio"
                      name="Ans"
                      id="A"
                      value="A"
                      v-model="link_ans"
                    />
                    A. <span v-html="subjectData.MCQ.A"></span>
                  </label>

                  <label
                    class="radio"
                    style="margin-right:50px;margin-left:50px;margin-bottom:10px;display:block;"
                  >
                    <input
                      type="radio"
                      name="Ans"
                      id="B"
                      value="B"
                      v-model="link_ans"
                    />
                    B. <span v-html="subjectData.MCQ.B"></span>
                  </label>

                  <label
                    class="radio"
                    style="margin-right:50px;margin-left:50px;margin-bottom:10px;display:block;"
                  >
                    <input
                      type="radio"
                      name="Ans"
                      id="C"
                      value="C"
                      v-model="link_ans"
                    />
                    C. <span v-html="subjectData.MCQ.C"></span>
                  </label>

                  <label
                    class="radio"
                    style="margin-right:50px;margin-left:50px;margin-bottom:10px;display:block;"
                  >
                    <input
                      type="radio"
                      name="Ans"
                      id="D"
                      value="D"
                      v-model="link_ans"
                    />
                    D. <span v-html="subjectData.MCQ.D"></span>
                  </label>
                </div>

                <h2
                  v-show="link_ans_bool == 0"
                  class="subtitle"
                  style="margin:30px;"
                >
                  What is the answer?
                </h2>

                <h2
                  v-show="link_ans_bool == 1"
                  class="subtitle"
                  style="margin:30px;"
                >
                  Corect, the answer is
                  <span v-html="subjectData.MCQ.Ans"></span>.
                </h2>

                <h2
                  v-show="link_ans_bool == 2"
                  class="subtitle"
                  style="margin:30px;"
                >
                  Wrong, the answer is
                  <span v-html="subjectData.MCQ.Ans"></span>.
                </h2>

                <h2
                  v-show="link_ans_bool == 3"
                  class="subtitle"
                  style="margin:30px;"
                >
                  You got {{ CorrectAnswers }}. You pass!
                </h2>

                <h2
                  v-show="link_ans_bool == 4"
                  class="subtitle"
                  style="margin:30px;"
                >
                  You got {{ CorrectAnswers }}. Try again later!
                </h2>
              </div>
            </div>

            <div
              class="buttons has-addons is-centered is-rounded is-large is-fullwidth"
              style="padding:10px;"
            >
              <button
                class="button is-large is-info is-light is-inverted is-outlined is-rounded"
                style="background-color:;"
                :disabled="link_ans_bool != 0"
                @click="
                  checkTestAns();
                  checkTestResult();
                "
              >
                <strong>Check</strong>

                <span class="icon">
                  <i class="fas fa-check"></i>
                </span>
              </button>

              <button
                v-if="currentQ < testQNum"
                class="button is-large is-info is-light is-inverted is-outlined is-rounded"
                style="background-color:;"
                :disabled="link_ans_bool == 0"
                @click="
                  currentQ++;
                  randomPage();
                  getSubData();
                  link_ans_bool = 0;
                  link_ans = '';
                "
              >
                <span>
                  <strong>Next</strong>
                </span>

                <span class="icon">
                  <i class="fas fa-forward"></i>
                </span>
              </button>

              <button
                v-if="currentQ == testQNum"
                class="button is-large is-info is-light is-inverted is-outlined is-rounded"
                style="background-color:;"
                :disabled="link_ans_bool == 0"
                @click="
                  updateUserTestData();
                  currentQ = 0;
                  CorrectAnswers = 0;
                  sh_menu = 1;
                  link_ans_bool = 0;
                  link_ans = '';
                "
              >
                <span>
                  <strong>End</strong>
                </span>

                <span class="icon">
                  <i class="fas fa-forward"></i>
                </span>
              </button>
            </div>
          </section>
        </div>

        <!-- Start card-->
        <div
          class="tile notification box is-primary"
          style="margin:10px;padding:0;"
          v-if="sh_read"
        >
          <section style="margin:auto">
            <div class="" style="">
              <span
                class="icon is-large"
                style="margin-top:35px;margin-bottom:25px;"
              >
                <i class="fas fa-4x fa-book-open"></i>
              </span>

              <h1 class="title" style="margin-bottom:30px;">
                Read the Text!
              </h1>

              <h2 class="subtitle" style="margin:30px;">
                Read the text and highlight
                <span class="icon ">
                  <i class="fas fa-paint-roller"></i>
                </span>

                anything you think is important.<br />
              </h2>
            </div>

            <div
              class="buttons has-addons is-centered is-rounded is-large "
              style="padding:10px;"
            >
              <button
                class="button is-large is-primary is-light is-inverted is-outlined is-rounded "
                style="background-color:#fdbdc2;"
                @click="
                  sh_link = 1;
                  sh_read = 0;
                "
              >
                <strong>Next</strong>

                <span class="icon">
                  <i class="fas fa-forward"></i>
                </span>
              </button>
            </div>
          </section>
        </div>

        <!-- Link question-->
        <div
          class="tile notification is-info"
          style="margin:10px;padding:0;"
          v-if="sh_link"
        >
          <section style="margin:auto">
            <div class="" style="">
              <span
                class="icon is-large"
                style="margin-top:35px;margin-bottom:25px;"
              >
                <i class="fas fa-4x fa-link"></i>
              </span>

              <h1 class="title" style="margin-bottom:30px;">
                Link Question
              </h1>
              <h2 class="subtitle">
                Start linking the knowledge to what you already know.<br /><br />
              </h2>
              <div
                class="card notification is-rounded is-light"
                style="border-radius: 5px;padding:1px;background-color:;"
              >
                <h2
                  class="subtitle"
                  style="margin:30px;text-align:left"
                  v-html="subjectData.MCQ.MCQuestion"
                ></h2>

                <div
                  class="control is-centered"
                  style="text-align:center;margin-top:15px;"
                >
                  <label
                    class="radio"
                    style="text-align:left;margin-right:50px;margin-left:50px;margin-bottom:10px;display:block;"
                  >
                    <input type="radio" name="Ans" id="A" value="A" />
                    A. <span v-html="subjectData.MCQ.A"></span>
                  </label>

                  <label
                    class="radio"
                    style="text-align:left;margin-right:50px;margin-left:50px;margin-bottom:10px;display:block;"
                  >
                    <input type="radio" name="Ans" id="B" value="B" />
                    B. <span v-html="subjectData.MCQ.B"></span>
                  </label>

                  <label
                    class="radio"
                    style="text-align:left;margin-right:50px;margin-left:50px;margin-bottom:10px;display:block;"
                  >
                    <input type="radio" name="Ans" id="C" value="C" />
                    C. <span v-html="subjectData.MCQ.C"></span>
                  </label>

                  <label
                    class="radio"
                    style="text-align:left;margin-right:50px;margin-left:50px;margin-bottom:10px;display:block;"
                  >
                    <input type="radio" name="Ans" id="D" value="D" />
                    D. <span v-html="subjectData.MCQ.D"></span>
                  </label>
                </div>

                <h2
                  v-show="link_ans_bool == 0"
                  class="subtitle"
                  style="margin:30px;text-align:left"
                >
                  What is the answer?
                </h2>

                <h2
                  v-show="link_ans_bool == 1"
                  class="subtitle"
                  style="margin:30px;text-align:left"
                >
                  The answer is <span v-html="subjectData.MCQ.Ans"></span>.
                </h2>
              </div>
            </div>

            <div
              class="buttons has-addons is-centered is-rounded is-large is-fullwidth"
              style="padding:10px;text-align:left"
            >
              <button
                class="button is-large is-info is-light is-inverted is-outlined is-rounded"
                style="background-color:;text-align:left"
                :disabled="link_ans_bool != 0"
                @click="
                  link_ans = 'subjectData.MCQ.Ans';
                  link_ans_bool = 1;
                "
              >
                <strong>Check</strong>

                <span class="icon">
                  <i class="fas fa-check"></i>
                </span>
              </button>

              <button
                class="button is-large is-info is-light is-inverted is-outlined is-rounded"
                style="background-color:;text-align:left"
                @click="
                  sh_link = 0;
                  sh_qr = 1;
                "
              >
                <strong>Next</strong>

                <span class="icon">
                  <i class="fas fa-forward"></i>
                </span>
              </button>
            </div>
          </section>
        </div>

        <!-- summary card-->
        <div
          class="tile notification is-primary"
          style="margin:10px;padding:0;"
          v-if="sh_summary"
        >
          <section style="margin:auto">
            <div class="" style="">
              <span
                class="icon is-large"
                style="margin-top:35px;margin-bottom:25px;"
              >
                <i class="fas fa-4x fa-clipboard"></i>
              </span>

              <h1 class="title" style="margin-bottom:30px;">
                Summary
              </h1>

              <h2 class="subtitle" style="margin:30px;">
                What you have learnt so far:<br />
              </h2>
            </div>
            <div
              class="card is-rounded"
              style="border-radius: 5px;padding:10px;background-color:;margin:10px;text-align:left"
            >
              <h2
                class="subtitle"
                style="margin:30px;text-align:left"
                v-html="this.subjectData.Notes"
              ></h2>
            </div>
            <div
              class="buttons has-addons is-centered is-rounded is-large is-fullwidth"
              style="padding:10px;"
            >
              <button
                class="button is-large is-primary is-light is-inverted is-outlined is-rounded"
                style="background-color:#fdbdc2;;"
                @click="resetData()"
              >
                <span class="icon">
                  <i class="fas fa-redo"></i>
                </span>
                <strong>Redo</strong>
              </button>

              <button
                class="button is-large is-primary is-light is-inverted is-outlined is-rounded"
                style="background-color:#fdbdc2;;"
                @click="
                  getProgress();
                  SetProgress();
                  sh_menu = true;
                  forceRerender();
                  resetData();
                "
              >
                <strong>Done</strong>

                <span class="icon">
                  <i class="fas fa-check"></i>
                </span>
              </button>
            </div>
          </section>
        </div>

        <!--summary notes total card -->
        <div
          class="tile notification is-primary"
          style="margin:10px;padding:0;"
          v-if="sh_sumNotes"
        >
          <section style="margin:auto">
            <div class="" style="">
              <span
                class="icon is-large"
                style="margin-top:35px;margin-bottom:25px;"
              >
                <i class="fas fa-4x fa-clipboard"></i>
              </span>

              <h1 class="title" style="margin-bottom:30px;">
                {{ currentTopic }} Numbered Notes
              </h1>
            </div>
            <div
              class="content card is-rounded"
              style="border-radius: 5px;padding:10px;background-color:;margin:10px;"
            >
              <ol type="1">
                <div
                  v-for="(i, index1) of topicData[currentTopic]"
                  :key="index1.id"
                  class="card is-rounded"
                  style="border-radius: 5px;padding:10px;background-color:;margin:10px;"
                >
                  <li style="margin-left:35px;font-size:25px">
                    <h2
                      class="subtitle"
                      style="margin:30px;font-size:25px"
                      v-html="i.Notes"
                    ></h2>
                  </li>
                </div>
              </ol>
            </div>
            <div
              class="buttons has-addons is-centered is-rounded is-large is-fullwidth"
              style="padding:10px;"
            >
              <button
                class="button is-large is-primary is-light is-inverted is-outlined is-rounded"
                style="background-color:#fdbdc2;;"
                @click="
                  sh_menu = true;
                  sh_sumNotes = false;

                  resetData();
                "
              >
                <strong>Done</strong>

                <span class="icon">
                  <i class="fas fa-check"></i>
                </span>
              </button>
            </div>
          </section>
        </div>

        <!--recipe r card-->
        <div
          class="tile notification is-dark"
          style="margin:10px;padding:0;"
          v-if="sh_qr"
        >
          <section style="margin:auto">
            <div class="" style="">
              <span
                class="icon is-large"
                style="margin-top:35px;margin-bottom:25px;"
              >
                <i class="fas fa-4x fa-clipboard"></i>
              </span>

              <h1 class="title" style="margin-bottom:30px;">
                <u>R</u>.E.C.I.P.E
              </h1>

              <h2 class="subtitle" style="margin:30px;">
                Reinforce the new information
              </h2>
            </div>
            <div
              class="card notification is-rounded is-light"
              style="border-radius: 5px;padding:1px;background-color:;"
            >
              <h2
                class="subtitle"
                style="margin:30px;text-align:left"
                v-html="this.subjectData.R"
              ></h2>
              <!--
              <div
                class="control is-centered"
                style="text-align:center;margin:0px 15px;"
              >
                <input class="input is-primary" type="text" v-model="qr_ans" />
              </div>
-->
              <h2
                v-show="qr_ans_bool == 0"
                class="subtitle"
                style="margin:30px;text-align:left"
              >
                What is the answer?
              </h2>

              <h2
                v-show="qr_ans_bool == 1"
                class="subtitle"
                style="margin:30px;text-align:left"
              >
                Correct, The answer is
                <span v-html="this.subjectData.Rans"></span>.
              </h2>

              <h2
                v-show="qr_ans_bool == 2"
                class="subtitle"
                style="margin:30px;text-align:left"
              >
                The answer is
                <span v-html="this.subjectData.Rans"></span>.
              </h2>
            </div>

            <div
              class="buttons has-addons is-centered is-rounded is-large is-fullwidth"
              style="padding:10px;"
            >
              <button
                class="button is-large is-info is-light is-inverted is-outlined is-rounded"
                style="background-color:;"
                :disabled="qr_ans_bool != 0"
                @click="
                  if (qr_ans == subjectData.Rans) {
                    qr_ans_bool = 1;
                    qr_ans = subjectData.Rans;
                  } else {
                    qr_ans_bool = 2;
                    qr_ans = subjectData.Rans;
                  }
                "
              >
                <strong>Check</strong>
                <span class="icon">
                  <i class="fas fa-check"></i>
                </span>
              </button>

              <button
                class="button is-large is-info is-light is-inverted is-outlined is-rounded"
                style="background-color:;"
                @click="
                  sh_qe = 1;
                  sh_qr = 0;
                "
              >
                <strong>Next</strong>

                <span class="icon">
                  <i class="fas fa-forward"></i>
                </span>
              </button>
            </div>
          </section>
        </div>

        <!--recipe e card-->
        <div
          class="tile notification is-dark"
          style="margin:10px;padding:0;"
          v-if="sh_qe"
        >
          <section style="margin:auto">
            <div class="" style="">
              <span
                class="icon is-large"
                style="margin-top:35px;margin-bottom:25px;"
              >
                <i class="fas fa-4x fa-clipboard"></i>
              </span>

              <h1 class="title" style="margin-bottom:30px;">
                R.<u>E</u>.C.I.P.E
              </h1>

              <h2 class="subtitle" style="margin:30px;">
                Reinforce the new information.
              </h2>
            </div>
            <div
              class="card notification is-rounded is-light"
              style="border-radius: 5px;padding:1px;background-color:;"
            >
              <h2
                class="subtitle"
                style="margin:30px;text-align:left"
                v-html="this.subjectData.E"
              ></h2>
              <!--
              <div
                class="control is-centered"
                style="text-align:center;margin:0px 15px;"
              >
                <input class="input is-primary" type="text" v-model="qe_ans" />
              </div>
-->
              <h2
                v-show="qe_ans_bool == 0"
                class="subtitle"
                style="margin:30px;text-align:left"
              >
                What is the answer?
              </h2>

              <h2
                v-show="qe_ans_bool == 1"
                class="subtitle"
                style="margin:30px;text-align:left"
              >
                Correct, The answer is
                <span v-html="this.subjectData.Eans"></span>.
              </h2>

              <h2
                v-show="qe_ans_bool == 2"
                class="subtitle"
                style="margin:30px;text-align:left"
              >
                The answer is
                <span v-html="this.subjectData.Eans"></span>.
              </h2>
            </div>

            <div
              class="buttons has-addons is-centered is-rounded is-large is-fullwidth"
              style="padding:10px;"
            >
              <button
                class="button is-large is-info is-light is-inverted is-outlined is-rounded"
                style="background-color:;"
                :disabled="qe_ans_bool != 0"
                @click="
                  if (qe_ans == subjectData.Eans) {
                    qe_ans_bool = 1;
                    qe_ans = subjectData.Eans;
                  } else {
                    qe_ans_bool = 2;
                    qe_ans = subjectData.Eans;
                  }
                "
              >
                <strong>Check</strong>

                <span class="icon">
                  <i class="fas fa-check"></i>
                </span>
              </button>

              <button
                class="button is-large is-info is-light is-inverted is-outlined is-rounded"
                style="background-color:;"
                @click="
                  sh_qc = 1;
                  sh_qe = 0;
                "
              >
                <strong>Next</strong>

                <span class="icon">
                  <i class="fas fa-forward"></i>
                </span>
              </button>
            </div>
          </section>
        </div>

        <!--recipe c card-->
        <div
          class="tile notification is-dark"
          style="margin:10px;padding:0;"
          v-if="sh_qc"
        >
          <section style="margin:auto">
            <div class="" style="">
              <span
                class="icon is-large"
                style="margin-top:35px;margin-bottom:25px;"
              >
                <i class="fas fa-4x fa-clipboard"></i>
              </span>

              <h1 class="title" style="margin-bottom:30px;">
                R.E.<u>C</u>.I.P.E
              </h1>

              <h2 class="subtitle" style="margin:30px;">
                Reinforce the new information.<br />
                Answer the questions.
              </h2>
            </div>
            <div
              class="card notification is-rounded is-light"
              style="border-radius: 5px;padding:1px;background-color:;"
            >
              <h2
                class="subtitle"
                style="margin:30px;text-align:left"
                v-html="this.subjectData.C"
              ></h2>
              <!--
              <div
                class="control is-centered"
                style="text-align:center;margin:0px 15px;"
              >
                <input class="input is-primary" type="text" v-model="qc_ans" />
              </div>
-->
              <h2
                v-show="qc_ans_bool == 0"
                class="subtitle"
                style="margin:30px;text-align:left"
              >
                What is the answer?
              </h2>

              <h2
                v-show="qc_ans_bool == 1"
                class="subtitle"
                style="margin:30px;text-align:left"
              >
                Correct, The answer is
                <span v-html="this.subjectData.Cans"></span>.
              </h2>

              <h2
                v-show="qc_ans_bool == 2"
                class="subtitle"
                style="margin:30px;text-align:left"
              >
                The answer is
                <span v-html="this.subjectData.Cans"></span>.
              </h2>
            </div>

            <div
              class="buttons has-addons is-centered is-rounded is-large is-fullwidth"
              style="padding:10px;"
            >
              <button
                class="button is-large is-info is-light is-inverted is-outlined is-rounded"
                style="background-color:;"
                :disabled="qc_ans_bool != 0"
                @click="
                  if (qc_ans == subjectData.Cans) {
                    qc_ans_bool = 1;
                    qc_ans = subjectData.Cans;
                  } else {
                    qc_ans_bool = 2;
                    qc_ans = subjectData.Cans;
                  }
                "
              >
                <strong>Check</strong>

                <span class="icon">
                  <i class="fas fa-check"></i>
                </span>
              </button>

              <button
                class="button is-large is-info is-light is-inverted is-outlined is-rounded"
                style="background-color:;"
                @click="
                  sh_qi = 1;
                  sh_qc = 0;
                "
              >
                <strong>Next</strong>

                <span class="icon">
                  <i class="fas fa-forward"></i>
                </span>
              </button>
            </div>
          </section>
        </div>

        <!--recipe i card-->
        <div
          class="tile notification is-dark"
          style="margin:10px;padding:0;"
          v-if="sh_qi"
        >
          <section style="margin:auto">
            <div class="" style="">
              <span
                class="icon is-large"
                style="margin-top:35px;margin-bottom:25px;"
              >
                <i class="fas fa-4x fa-clipboard"></i>
              </span>

              <h1 class="title" style="margin-bottom:30px;">
                R.E.C.<u>I</u>.P.E
              </h1>

              <h2 class="subtitle" style="margin:30px;">
                Reinforce the new information.<br />
                Answer the questions.
              </h2>
            </div>
            <div
              class="card notification is-rounded is-light"
              style="border-radius: 5px;padding:1px;background-color:;"
            >
              <h2
                class="subtitle"
                style="margin:30px;text-align:left"
                v-html="this.subjectData.I"
              ></h2>
              <!--
              <div
                class="control is-centered"
                style="text-align:center;margin:0px 15px;"
              >
                <input class="input is-primary" type="text" v-model="qi_ans" />
              </div>
-->
              <h2
                v-show="qi_ans_bool == 0"
                class="subtitle"
                style="margin:30px;text-align:left"
              >
                What is the answer?
              </h2>

              <h2
                v-show="qi_ans_bool == 1"
                class="subtitle"
                style="margin:30px;text-align:left"
              >
                Correct, The answer is
                <span v-html="this.subjectData.Ians"></span>.
              </h2>

              <h2
                v-show="qi_ans_bool == 2"
                class="subtitle"
                style="margin:30px;text-align:left"
              >
                The answer is
                <span v-html="this.subjectData.Ians"></span>.
              </h2>
            </div>

            <div
              class="buttons has-addons is-centered is-rounded is-large is-fullwidth"
              style="padding:10px;"
            >
              <button
                class="button is-large is-info is-light is-inverted is-outlined is-rounded"
                style="background-color:;"
                :disabled="qi_ans_bool != 0"
                @click="
                  if (qi_ans == subjectData.Ians) {
                    qi_ans_bool = 1;
                    qi_ans = subjectData.Ians;
                  } else {
                    qi_ans_bool = 2;
                    qi_ans = subjectData.Ians;
                  }
                "
              >
                <strong>Check</strong>

                <span class="icon">
                  <i class="fas fa-check"></i>
                </span>
              </button>

              <button
                class="button is-large is-info is-light is-inverted is-outlined is-rounded"
                style="background-color:;"
                @click="
                  sh_qp = 1;
                  sh_qi = 0;
                "
              >
                <strong>Next</strong>

                <span class="icon">
                  <i class="fas fa-forward"></i>
                </span>
              </button>
            </div>
          </section>
        </div>

        <!--recipe p card-->
        <div
          class="tile notification is-dark"
          style="margin:10px;padding:0;"
          v-if="sh_qp"
        >
          <section style="margin:auto">
            <div class="" style="">
              <span
                class="icon is-large"
                style="margin-top:35px;margin-bottom:25px;"
              >
                <i class="fas fa-4x fa-clipboard"></i>
              </span>

              <h1 class="title" style="margin-bottom:30px;">
                R.E.C.I.<u>P</u>.E
              </h1>

              <h2 class="subtitle" style="margin:30px;">
                Reinforce the new information.<br />
                Answer the questions.
              </h2>
            </div>
            <div
              class="card notification is-rounded is-light"
              style="border-radius: 5px;padding:1px;background-color:;"
            >
              <h2
                class="subtitle"
                style="margin:30px;text-align:left"
                v-html="this.subjectData.P"
              ></h2>
              <!--
              <div
                class="control is-centered"
                style="text-align:center;margin:0px 15px;"
              >
                <input class="input is-primary" type="text" v-model="qp_ans" />
              </div>
-->
              <h2
                v-show="qp_ans_bool == 0"
                class="subtitle"
                style="margin:30px;text-align:left"
              >
                What is the answer?
              </h2>

              <h2
                v-show="qp_ans_bool == 1"
                class="subtitle"
                style="margin:30px;text-align:left"
              >
                Correct, The answer is
                <span v-html="this.subjectData.Pans"></span> .
              </h2>

              <h2
                v-show="qp_ans_bool == 2"
                class="subtitle"
                style="margin:30px;text-align:left"
              >
                The answer is
                <span v-html="this.subjectData.Pans"></span>.
              </h2>
            </div>

            <div
              class="buttons has-addons is-centered is-rounded is-large is-fullwidth"
              style="padding:10px;"
            >
              <button
                class="button is-large is-info is-light is-inverted is-outlined is-rounded"
                style="background-color:;"
                :disabled="qp_ans_bool != 0"
                @click="
                  if (qp_ans == subjectData.Pans) {
                    qp_ans_bool = 1;
                    qp_ans = subjectData.Pans;
                  } else {
                    qp_ans_bool = 2;
                    qp_ans = subjectData.Pans;
                  }
                "
              >
                <strong>Check</strong>

                <span class="icon">
                  <i class="fas fa-check"></i>
                </span>
              </button>

              <button
                class="button is-large is-info is-light is-inverted is-outlined is-rounded"
                style="background-color:;"
                @click="
                  sh_qE = 1;
                  sh_qp = 0;
                "
              >
                <strong>Next</strong>

                <span class="icon">
                  <i class="fas fa-forward"></i>
                </span>
              </button>
            </div>
          </section>
        </div>

        <!--recipe E card-->
        <div
          class="tile notification is-dark"
          style="margin:10px;padding:0;"
          v-if="sh_qE"
        >
          <section style="margin:auto">
            <div class="" style="">
              <span
                class="icon is-large"
                style="margin-top:35px;margin-bottom:25px;"
              >
                <i class="fas fa-4x fa-clipboard"></i>
              </span>

              <h1 class="title" style="margin-bottom:30px;">
                R.E.C.I.P.<u>E</u>
              </h1>

              <h2 class="subtitle" style="margin:30px;">
                Reinforce the new information.<br />
                Answer the questions.
              </h2>
            </div>
            <div
              class="card notification is-rounded is-light"
              style="border-radius: 5px;padding:1px;background-color:;"
            >
              <h2
                class="subtitle"
                style="margin:30px;text-align:left"
                v-html="subjectData.V"
              ></h2>
              <!--
              <div
                class="control is-centered"
                style="text-align:center;margin:0px 15px;"
              >
                <input class="input is-primary" type="text" v-model="qE_ans" />
              </div>
              -->
              <h2
                v-show="qE_ans_bool == 0"
                class="subtitle"
                style="margin:30px;text-align:left"
              >
                What is the answer?
              </h2>

              <h2
                v-show="qE_ans_bool == 1"
                class="subtitle"
                style="margin:30px;text-align:left"
              >
                Correct, The answer is
                <span v-html="subjectData.Vans"></span>.
              </h2>

              <h2
                v-show="qE_ans_bool == 2"
                class="subtitle"
                style="margin:30px;text-align:left"
              >
                The answer is <span v-html="subjectData.Vans"></span>.
              </h2>
            </div>

            <div
              class="buttons has-addons is-centered is-rounded is-large is-fullwidth"
              style="padding:10px;"
            >
              <button
                class="button is-large is-info is-light is-inverted is-outlined is-rounded"
                style="background-color:;"
                :disabled="qE_ans_bool != 0"
                @click="
                  if (qE_ans == subjectData.Vans) {
                    qE_ans_bool = 1;
                    qE_ans = subjectData.Vans;
                  } else {
                    qE_ans_bool = 2;
                    qE_ans = subjectData.Vans;
                  }
                "
              >
                <strong>Check</strong>

                <span class="icon">
                  <i class="fas fa-check"></i>
                </span>
              </button>

              <button
                class="button is-large is-info is-light is-inverted is-outlined is-rounded"
                style="background-color:;"
                @click="
                  sh_summary = 1;
                  sh_qE = 0;
                  SetProgress();
                "
              >
                <strong>Next</strong>

                <span class="icon">
                  <i class="fas fa-forward"></i>
                </span>
              </button>
            </div>
          </section>
        </div>
      </div>
    </div>

    <!--Menu Card-->

    <aside
      class="menu box card"
      v-if="sh_menu && renderComponent && topicData"
      style="margin:30px;text-align:left;background-color:#fdbdc2;"
    >
      <ul class="menu-list">
        <li v-for="(i, index1) of topicData" :key="index1">
          <div v-if="index1 == currentTopic">
            <p class="menu-label">
              <span>
                <strong
                  ><u>{{ i.heading }} </u></strong
                >
              </span>

              <i>
                <span
                  style="margin-left:8px; font-size:9px;"
                  class="has-text-primary"
                  v-if="testdisplay(index1)"
                  >Passed
                  <span v-if="convertDate(testDatedisplay(index1))">
                    <u>{{ convertDate(testDatedisplay(index1)) }} days ago</u>
                  </span>
                </span>
              </i>
            </p>
          </div>
          <div v-else>
            <p class="menu-label">
              {{ i.heading }}

              <i>
                <span
                  style="margin-left:8px; font-size:9px;"
                  class="has-text-primary"
                  v-if="testdisplay(index1)"
                  >Passed
                  <span v-if="convertDate(testDatedisplay(index1))">
                    <u>{{ convertDate(testDatedisplay(index1)) }} days ago</u>
                  </span>
                </span>
              </i>
            </p>
          </div>

          <ul style="text-align:right;">
            <li style="margin:0px 0px 10px 10px;padding:0px 0px 20px 20px">
              <p
                class="menu-label"
                style="text-align:left;float:left;font-size:13px;width:100%;"
              >
                <span
                  class="doTest"
                  style="text-align:left;float:right;font-size:13px;margin-top:-6px;margin-bottom:12px;"
                  ><a
                    style="margin-left:2px;"
                    class="has-text-light button is-primary is-small"
                    @click="
                      currentTopic = index1;
                      sh_menu = false;
                      sh_sumNotes = true;
                      sh_read = false;
                    "
                    >Notes
                  </a>
                </span>

                <span
                  class="doTest"
                  style="text-align:left;float:right;font-size:13px;margin-top:-6px;"
                  ><a
                    style="margin-left:2px;"
                    class=" has-text-light button is-primary is-small"
                    @click="
                      currentTopic = index1;
                      sh_menu = false;
                      sh_test = true;
                      sh_read = false;
                      randomPage();
                      sh_menu;
                    "
                  >
                    quiz
                  </a>
                </span>
              </p>
            </li>
            <li v-for="(j, index) of i" :key="index" style="text-align:left;">
              <div v-if="index != 'heading'">
                <div
                  v-if="index == currentSubtopic && index1 == currentTopic"
                  style="text-align:left;"
                >
                  <a
                    style="text-align:left;"
                    class="is-active"
                    @click="
                      currentTopic = index1;
                      currentSubtopic = index;
                      getSubData();
                      resetData();
                      sh_menu = false;
                    "
                    ><span style="text-align:left;" v-html="j.heading"></span>

                    <strong>
                      <!-- -->

                      <span
                        style="text-align:right;float:right;font-size:13px;"
                      ></span
                    ></strong>
                  </a>
                </div>
                <div v-else>
                  <a
                    @click="
                      getCurrentScrollPosition();
                      currentTopic = index1;
                      currentSubtopic = index;
                      getSubData();
                      sh_menu = false;
                      resetData();
                      gotoTop();
                    "
                    ><span style="text-align:left;" v-html="j.heading"></span>

                    <span
                      v-html="progdisplay(index1, index)"
                      class=""
                      style="text-align:right;float:right;font-size:13px;"
                    ></span>
                  </a>
                </div>
              </div>
            </li>
          </ul>
        </li>
      </ul>
    </aside>
  </div>
</template>

<script>
import firebase from "firebase";

var scroll_position;

export default {
  data() {
    return {
      resetContent: "",
      content_alias: "",
      renderComponent: true,
      currentTopic: "topic01",
      currentSubtopic: "subtopic01",
      scrollPosition: "",
      subjectData: {
        content: "",
        contentImage: "",
        heading: ""
      },
      topicData: "",
      progress: 0,
      pjay: 0,
      testQNum: 5,
      currentQ: 1,
      CorrectAnswers: 0,
      sh_test: false,
      pass: 0,

      sh_sumNotes: 0,

      eSub: false,
      eMod: true,
      mod: 0,
      sh_read: false,

      sh_link: false,
      link_ans: "",
      link_ans_bool: 0,

      sh_qr: false,
      qr_ans: "",
      qr_ans_bool: 0,

      sh_qe: false,
      qe_ans: "",
      qe_ans_bool: 0,

      sh_qc: false,
      qc_ans: "",
      qc_ans_bool: 0,

      sh_qi: false,
      qi_ans: "",
      qi_ans_bool: 0,

      sh_qp: false,
      qp_ans: "",
      qp_ans_bool: 0,

      sh_qE: false,
      qE_ans: "",
      qE_ans_bool: 0,

      sh_menu: true,

      sh_summary: false,

      hl_var: false,

      hiClass: ""
    };
  },
  components: {},
  computed: {},
  methods: {
    // populates screen data
    convertDate: function(oldD) {
      const _MS_PER_DAY = 1000 * 60 * 60 * 24;
      var a = new Date();
      var b = new Date(oldD);
      // Discard the time and time-zone information.
      const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
      const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

      return Math.abs(Math.floor((utc2 - utc1) / _MS_PER_DAY));
    },
    getSubData: function() {
      var subjectData = this.subjectData;
      var SubjectRef = firebase
        .database()
        .ref(
          "subjects/" +
            this.$route.params.subjectTitle +
            "_" +
            this.$route.params.subjectLevel +
            "_" +
            this.$route.params.subjectBoard +
            "/topics/" +
            this.currentTopic +
            "/" +
            this.currentSubtopic
        );

      SubjectRef.on("value", function(snapshot) {
        subjectData = snapshot.val();
      });
      if (subjectData) {
        this.subjectData = subjectData;
        this.content_alias = this.subjectData.content;
        this.resetContent = this.subjectData.content;
      }
    },
    // populates menu topics sets user data progress
    getTopicData: function() {
      let uid = firebase.auth().currentUser.uid;
      var topicData;
      var hasProgress = false;
      var progress = {};
      var pjay = {};
      var thing = this.$store.state.userSubjectData[
        this.$route.params.subjectTitle +
          "_" +
          this.$route.params.subjectLevel +
          "_" +
          this.$route.params.subjectBoard
      ];
      var topicRef = firebase
        .database()
        .ref(
          "subjects/" +
            this.$route.params.subjectTitle +
            "_" +
            this.$route.params.subjectLevel +
            "_" +
            this.$route.params.subjectBoard +
            "/topics"
        );

      if (
        firebase
          .database()
          .ref(
            "subjects/" +
              this.$route.params.subjectTitle +
              "_" +
              this.$route.params.subjectLevel +
              "_" +
              this.$route.params.subjectBoard +
              "/topics"
          )
      ) {
      }

      let userSubjectProgressRef = firebase
        .database()
        .ref(
          "/users/" +
            uid +
            "/subjects/" +
            this.$route.params.subjectTitle +
            "_" +
            this.$route.params.subjectLevel +
            "_" +
            this.$route.params.subjectBoard
        );
      if (this.$store.state.userSubjectData) {
        if (thing.progress) {
          hasProgress = true;
        }
      }

      topicRef.on("value", function(snapshot) {
        topicData = snapshot.val();

        if (!hasProgress) {
          var time = {};
          var i, j;
          for (i in topicData) {
            time = {};
            //console.log(i);

            for (j in topicData[i]) {
              time[j] = { time: "new" }; //currentTime;
              //console.log(time);
            }
            //console.log(time);
            time["passed"] = false;
            time["testDate"] = "new";
            progress[i] = time; //pjay;
          }

          userSubjectProgressRef.update({
            progress
          });
        }
      });

      if (topicData) this.topicData = topicData;
    },
    SetProgress: function() {
      let uid = firebase.auth().currentUser.uid;
      var subjectData = this.Subjectdata;
      var time = this.currentTime;
      var userSubjectTopicRef = firebase
        .database()
        .ref(
          "/users/" +
            uid +
            "/subjects/" +
            this.$route.params.subjectTitle +
            "_" +
            this.$route.params.subjectLevel +
            "_" +
            this.$route.params.subjectBoard +
            "/progress/" +
            this.currentTopic +
            "/" +
            this.currentSubtopic
        );

      userSubjectTopicRef.update({
        time
      });
    },
    getProgress: function() {
      let uid = firebase.auth().currentUser.uid;
      var prog;
      var time = this.currentTime;
      var userSubjectTopicRef = firebase
        .database()
        .ref(
          "/users/" +
            uid +
            "/subjects/" +
            this.$route.params.subjectTitle +
            "_" +
            this.$route.params.subjectLevel +
            "_" +
            this.$route.params.subjectBoard +
            "/progress"
        );

      userSubjectTopicRef.on("value", function(snapshot) {
        prog = snapshot.val();
      });
      this.progress = prog;
      this.forceRerender();
      //return prog;
      //console.log("prog");
    },
    toggleDisplay(input) {
      if (input.showing == "is-active") {
        input.showing = "";
      } else {
        input.showing = "is-active";
      }
      //console.log(input.showing);
    },
    progdisplay(a, b) {
      //console.log("hello");
      var c = this.progress[a];
      var d = c[b];
      if (isNaN(this.convertDate(d.time))) {
      } else if (this.convertDate(d.time) == 0) {
        return "last access: Today";
      } else {
        return "last access: " + this.convertDate(d.time) + "days ago";
      }
    },
    testdisplay(a) {
      var c = this.progress[a];
      return c.passed;
    },
    testDatedisplay(a) {
      var c = this.progress[a];
      return c.testDate;
    },
    forceRerender() {
      // Remove my-component from the DOM
      this.renderComponent = false;

      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true;
        //this.getProgress
      });
    },
    resetData() {
      this.currentQ = 1;
      this.CorrectAnswers = 0;
      this.sh_test = false;
      this.pass = 0;
      this.sh_test = 0;
      this.sh_read = 1;
      this.sh_summary = 0;
      this.sh_sumNotes = 0;
      this.sh_link = 0;
      this.sh_qr = 0;
      this.sh_qe = 0;
      this.sh_qi = 0;
      this.sh_qc = 0;
      this.sh_qp = 0;
      this.sh_qE = 0;

      this.link_ans = "";
      this.link_ans_bool = 0;
      this.qr_ans = "";
      this.qr_ans_bool = 0;
      this.qe_ans = "";
      this.qe_ans_bool = 0;
      this.qi_ans = "";
      this.qi_ans_bool = 0;
      this.qc_ans = "";
      this.qc_ans_bool = 0;
      this.qp_ans = "";
      this.qp_ans_bool = 0;
      this.qE_ans = "";
      this.qE_ans_bool = 0;
    },
    checkTestAns() {
      if (this.link_ans == this.subjectData.MCQ.Ans) {
        this.link_ans_bool = 1;
        this.CorrectAnswers++;
      } else {
        this.link_ans_bool = 2;
      }
    },
    checkTestResult() {
      if (this.testQNum == this.currentQ) {
        if (this.CorrectAnswers / this.testQNum > 0.7) {
          this.pass = 1;
          //console.log(this.pass);
        } else {
          this.pass = 2;
          //console.log(this.pass);
        }
      }
    },
    updateUserTestData() {
      let uid = firebase.auth().currentUser.uid;
      var subjectData = this.Subjectdata;
      var time = this.currentTime;
      var userSubjectTopicRef = firebase
        .database()
        .ref(
          "/users/" +
            uid +
            "/subjects/" +
            this.$route.params.subjectTitle +
            "_" +
            this.$route.params.subjectLevel +
            "_" +
            this.$route.params.subjectBoard +
            "/progress/" +
            this.currentTopic
        );

      if ((this.pass = 1)) {
        userSubjectTopicRef.update({
          testDate: time,
          passed: true
        });
      }
    },
    randomPage() {
      let subTopicNo = Object.keys(this.progress[this.currentTopic]).length - 2;
      let randomNo = Math.floor(Math.random() * subTopicNo) + 1;

      let val = Object.keys(this.progress[this.currentTopic]);
      this.currentSubtopic = val[randomNo];
      //console.log(val[randomNo]);
    },
    logSelection(inputContent) {
      // var selection = e.target.html.substring(
      //  e.target.selectionStart,
      //  e.target.selectionEnd
      // );
      //console.log(window.getSelection().toString());
      var selection = window.getSelection().toString();
      let re = new RegExp(selection, "i");

      this.content_alias = inputContent.replace(
        re,
        "<span class='highlightText'>" + selection + "</span>"
      );
      this.subjectData.content = this.content_alias;
    },
    gotoTop() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
    getCurrentScrollPosition: function() {
      scroll_position = document.body.scrollTop;
      console.log(scroll_position);
    },
    setCurrentScrollPosition: function() {
      document.documentElement.scrollTop = scroll_position;
      document.body.scrollTop = scroll_position;
      console.log(scroll_position);
    }
  },
  watch: {},
  updated: function() {
    //this.$nextTick(this.getSubData);
    //this.SetProgress;
    this.getProgress;
    this.getSubData;
    this.getTopicData;
    //this.$nextTick(this.getProgress);

    //console.log(this.currentSubtopic);
    //console.log(this.currentTopic);
    if (this.sh_menu == true) {
      this.setCurrentScrollPosition();
    }
  },
  created: function() {
    this.$nextTick(this.gotoTop);
    this.getTopicData;
    //this.$router.onReady(this.getSubData);
    //this.$router.onReady(this.getProgress);
    //this.$router.onReady(this.getSubData);
    this.$nextTick(this.getSubData);
    this.$nextTick(this.getTopicData);
    this.$nextTick(this.getProgress);
  },
  computed: {
    currentTime: function() {
      let time = new Date();
      return time;
    },
    progBarVal: function() {
      return (this.currentQ / this.testQNum) * 100;
    },
    imgAddress: function() {
      return this.subjectData.contentImage;
    }
  },
  mounted() {}
};
</script>

<style lang="scss">
$col1: #db5d78;
$col2: #efa9c7;
$col3: #f4dcee;
$col4: #fdbdc2;
$col5: #f5f2f2;

.red {
  background-color: #efa9c7;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.highlightText {
  background: $col2;
}
</style>

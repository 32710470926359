<template lang="html">
  <div class="" style="width:100%; min-width:380px;margin:auto; ">
    <div
      id="navMenu"
      class=""
      style="text-align:left;padding:20px;position:sticky;width:100%;top:-1px;z-index:10;"
    >
      <div class="" style="padding:0px">
        <span class="title" style="top:100px;">Preferences</span>
      </div>
    </div>

    <div
      class="card-content box"
      style="width:50%;margin:auto;min-width:380px;margin-top:10px;"
    >
      <div class="tile is-ancestor">
        <div class="tile is-parent is-vertical">
          <div class="tile is-child">
            <br />
            <fieldset class=" card" style="">
              <label class="label"
                >Subscribed: <u>{{ userdat.subscribed }}</u></label
              >
              <label class="label"
                >Email Verified: <u>{{ getIsVerified }}</u></label
              >
              <label class="label">
                Get Emails:
                <u>{{ userdat.emailUpdates }}</u></label
              >
            </fieldset>
            <br />
            <a
              v-if="!$store.state.userData.emailVerified"
              @click="verifyEmail"
              class="button is-success is-light is-medium"
              >Verify Email <i> </i
            ></a>
            <br />

            <a
              v-show="0"
              v-if="!$store.state.userDataR.Subscribed"
              class="button is-secondary"
              >Subscribe</a
            >
            <br />
            <br />
            <a
              @click="
                toggleProgressEmails();
                getuserdata();
              "
              v-if="!$store.state.userDataR.Subscribed"
              class="button is-secondary"
            >
              Get Emails?
            </a>
            <br />
            <br />
            <br />
            <br />

            <a class="button is-danger" @click="deleteUser">Delete acount</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import router from "@/router";
import firebase, { functions } from "firebase";
export default {
  data() {
    return {
      show1: false,
      userdat: {
        subscribed: false
      }
    };
  },
  methods: {
    deleteUser: function() {
      var user = firebase.auth().currentUser;

      user
        .delete()
        .then(function() {
          store.state.userData.N = 0;
          alert("The account has been deleted.");
          router.push("About");
        })
        .catch(function(error) {
          alert(error);
        });
    },
    verifyEmail: function() {
      var user = firebase.auth().currentUser;

      user.sendEmailVerification().then(function() {
        alert("Email verification sent to " + store.state.userData.email);
        firebase
          .auth()
          .signOut()
          .then(() => {
            store.state.userData.N = 0;
            alert("Log in after you have verified email.");
            router.push("About");
          });
        this.loggedOn = 0;
        this.$store.state.userData.N.length = 0;
      });
    },
    toggleProgressEmails: function() {
      store.state.userDataR.emailUpdates = !store.state.userDataR.emailUpdates;
      let uid = firebase.auth().currentUser.uid;
      firebase
        .database()
        .ref()
        .child("users/" + uid)
        .update({
          emailUpdates: !this.userdat.emailUpdates
        });
    },
    getuserdata: function() {
      let uid = firebase.auth().currentUser.uid;
      var SubList;
      firebase
        .database()
        .ref()
        .child("users/" + uid)
        .on("value", function(snapshot) {
          SubList = snapshot.val();
          //console.log(SubList);
        });
      this.userdat = SubList;
    }
  },
  updated() {
    this.getuserdata;
    // this.$forceUpdate();
  },
  created() {
    this.getuserdata;
    this.$router.onReady(this.getuserdata);
    this.$nextTick(this.getuserdata);
    this.$forceUpdate();
  },
  computed: {
    getIsVerified: function() {
      var user = firebase.auth().currentUser;
      return user.emailVerified;
    }
  },
  mounted: function() {
    this.$forceUpdate();
  },
  destroyed() {
    this.$forceUpdate();
  }
};
</script>

<style lang="css"></style>

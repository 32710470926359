<template lang="html">
  <div class="hero is-fullheight is-primary">
    <div
      class="card"
      style="width:40%; min-width:315px;margin:auto;margin-top:auto; "
    >
      <div class="car-header">
        <div class="card-header-title is-centered">
          <div class="tabs is-medium">
            <ul>
              <li :class="[su_cl]">
                <a
                  style=""
                  @click="
                    sgnUp = true;
                    sgnIn = false;
                    su_cl = 'has-text-primary';
                    si_cl = '';
                  "
                >
                  <span class="icon is-small has-text-dark"
                    ><i class="fas fa-user-plus" aria-hidden="flase"></i>
                  </span>
                  <span class="has-text-dark">
                    Sign Up
                  </span>
                  <hr v-show="sgnIn" />
                </a>
              </li>

              <li :class="[si_cl]">
                <a
                  @click="
                    sgnIn = true;
                    sgnUp = false;
                    si_cl = 'has-text-primary';
                    su_cl = '';
                  "
                >
                  <span class="icon is-small has-text-dark"
                    ><i class="fas fa-sign-in-alt" aria-hidden="true"
                  /></span>
                  <span class="has-text-dark">Sign In</span>
                  <hr v-show="sgnUp" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="card-content">
        <div class="field">
          <label class="label">Email</label>
          <div class="control">
            <input
              v-model="email"
              class="input"
              type="email"
              placeholder=""
              value=""
            />
          </div>
          <p class="help" v-show="0">This email is invalid</p>
        </div>

        <div class="field ">
          <label class="label">Password</label>
          <div class="control">
            <input
              v-model="password"
              class="input"
              type="password"
              placeholder=""
            />
          </div>
          <p class="help is-success" v-show="0">Strong Password</p>
        </div>

        <a
          v-show="sgnUp"
          class=" has-text-grey"
          style=""
          @click="TandC = !TandC"
          >Buy signing up you are agreeing with the
          <u>terms and conditions</u></a
        >

        <div class="card" v-show="TandC">Terms and Conditions</div>
      </div>

      <div class="card-footer is-centered">
        <div
          class="field is-grouped"
          style="margin:auto;margin-top:10px;margin-bottom:10px;"
        >
          <div class="control">
            <button class="button" @click="signFunc">Enter</button>
          </div>

          <div class="control">
            <button class="button">
              <router-link to="/About">Back</router-link>
            </button>
          </div>

          <div v-show="sgnIn" class="control">
            <button class="button" @click="forgotPwordFunc">
              Forgot Password
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import router from "@/router";
import store from "@/store";

export default {
  name: "SignIn",
  data() {
    return {
      sgnIn: true,
      sgnUp: false,
      su_cl: "",
      si_cl: "is-active has-text-primary",
      email: "",
      password: "",
      sharedState: store.state,
      sgnUpComplete: false,
      shVerifyEmail: false,
      errorMsg: "",
      TandC: false
    };
  },
  methods: {
    signFunc: function() {
      if (this.sgnIn == false) {
        // sign up
        firebase
          .auth()
          .createUserWithEmailAndPassword(this.email, this.password)
          .then(
            function(user) {
              router.push("svSubSelect");
              store.state.user = user.user;

              firebase
                .database()
                .ref()
                .child("users/" + user.user.uid)
                .update({
                  admin: false,
                  email: user.user.email,
                  emailUpdates: false,
                  startDate: new Date(),
                  subscribed: false,
                  lastLogOn: new Date()
                });
            },
            function(err) {
              alert(err);
            }
          );
      } else {
        // sign in
        firebase
          .auth()
          .signInWithEmailAndPassword(this.email, this.password)
          .then(
            function(user) {
              store.state.user = user.user;
              //alert("Signed In");
              firebase
                .database()
                .ref()
                .child("users/" + user.user.uid)
                .update({
                  lastLogOn: new Date()
                });
              router.push("svSubSelect");
            },
            function(err) {
              alert(err);
            }
          );
      }
    },
    initUserData: function() {
      let uid = firebase.auth().currentUser.uid;
      firebase
        .database()
        .ref()
        .child("users/" + this.uid)
        .update({
          email: user.user.email,
          startDate: this.currentTime,
          Subscribed: false,
          lastLogOn: this.currentTime,
          admin: false
        });
    },
    updateUserData: function() {
      let uid = firebase.auth().currentUser.uid;
      let hasSubject = false;
      firebase
        .database()
        .ref()
        .child("users/" + this.uid)
        .update({
          lastLogOn: this.currentTime
        });
    },
    forgotPwordFunc: function() {
      var auth = firebase.auth();
      var emailAddress = this.email;
      auth
        .sendPasswordResetEmail(emailAddress)
        .then(function() {
          alert("Password resest email sent.");
        })
        .catch(function(error) {
          alert(error);
        });
    }
  },
  computed: {
    currentTime: function() {
      let time = new Date();
      return time;
    }
  }
};
</script>

<style lang="css"></style>
